.contentHeader.Hero .heroContainer .contentContainer .textContainer {
  border-radius: 20px;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseTextTheme h2 {
  color: white;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseTextTheme a.button:focus {
  outline-color: white;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseFullTheme h2 {
  color: white;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseFullTheme a.button {
  color: #002e6d;
  background: #fff;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseFullTheme a.button:focus {
  outline-color: white;
}
