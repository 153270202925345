.searchBoxContainer {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  background-color: #002557;
  padding: 10px;
}
.searchBoxContainer:before {
  position: absolute;
  z-index: 1;
  top: 22px;
  left: 22px;
  font-family: 'Icon Fonts';
  content: "\e92c";
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer {
  position: relative;
  display: inherit;
  width: 100%;
}
.searchBoxContainer .searchTermContainer input.searchInput {
  width: 100%;
  padding: 10px 38px 10px 38px;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #333;
  background-color: #fff;
  border: 2px solid #002557;
  border-radius: 100px 0 0 100px;
  border-right-width: 1px;
}
.searchBoxContainer .searchTermContainer input.searchInput::-webkit-input-placeholder {
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer input.searchInput:-ms-input-placeholder {
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer input.searchInput::placeholder {
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer input.searchInput::-ms-clear {
  display: none;
}
.searchBoxContainer .searchTermContainer input.searchInput:focus {
  outline: 2px dotted #002e6d;
  outline-offset: -8px;
  border-radius: 100px 0 0 100px;
}
.searchBoxContainer .searchTermContainer .clearInputButton {
  position: absolute;
  width: 20px;
  height: 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0px;
  background: none;
  top: calc(50% - 10px);
  right: 12px;
  color: #002e6d;
}
.searchBoxContainer button.searchLinkButton {
  padding: 12px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  font-weight: 700;
  white-space: nowrap;
  background-color: #fff9e4;
  color: #002e6d;
  border: 2px solid #002557;
  border-radius: 0 100px 100px 0;
  border-left-width: 1px;
}
.searchBoxContainer button.searchLinkButton:hover {
  text-decoration: none;
}
.searchBoxContainer button.searchLinkButton:focus {
  outline: 2px dotted #002e6d;
  outline-offset: -8px;
  border-radius: 0 100px 100px 0;
}
