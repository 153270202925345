.contentListContainer .ratingsAndReviewCount .stars:before {
  color: #002e6d;
}
.contentListContainer .ratingsAndReviewCount .ratingCount {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  vertical-align: text-top;
}
