.featuredContent .textContainer .linkButton.invertButtonStyle a {
  background-color: white;
  color: #002e6d;
}
.featuredContent .textContainer .linkButton a {
  color: white;
  background-color: #002e6d;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
}
.featuredContent.darkBlueTheme .textContainer {
  background-color: #002e6d;
}
.featuredContent.darkBlueTheme .textContainer .headerText h2 {
  color: white;
}
.featuredContent.darkBlueTheme .textContainer p {
  color: white;
}
.featuredContent.darkBlueTheme .textContainer p span {
  color: white;
}
.featuredContent.darkBlueTheme .textContainer p a,
.featuredContent.darkBlueTheme .textContainer p a:focus {
  color: white;
  outline-color: white;
}
.featuredContent.darkBlueTheme .textContainer .linkButton a {
  border: none;
  background-color: white;
  color: #002e6d;
}
.featuredContent.creamTheme .textContainer {
  background-color: #fff9e4;
}
