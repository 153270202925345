.contentListContainer .categoryTile.creamTheme {
  background-color: #fff9e4;
}
.contentListContainer .categoryTile.darkBlueTheme {
  background-color: #002e6d;
}
.contentListContainer .categoryTile.darkBlueTheme .headingContainer {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .headingContainer h2 {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .headingContainer .descriptionContainer a {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .tileContainer .contentItemsWrapper .contentItem .itemLink {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .tileContainer .contentItemsWrapper .contentItem .itemLink:focus {
  outline-color: white;
}
