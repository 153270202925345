.cgcRedesignContainer .contentListContainer .reviewSummarySection {
  border-radius: 24px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown > .sortDropdownInnerButton:focus {
  outline: 3px dotted white;
  outline-offset: 3px;
  border-radius: 3px;
}
.cgcRedesignContainer .contentListContainer .emptyListContainer {
  border-radius: 16px;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  color: #002e6d;
  background: #fff;
  border: solid 2px #002e6d;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton:hover {
  text-decoration: none;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton a {
  color: #fff;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton:focus {
  border-radius: 100px;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton:focus {
  border-radius: 100px;
}
