.verticalVideoList {
  margin: 20px 0;
}
.verticalVideoList .contentContainer {
  min-height: 600px;
}
.verticalVideoList .contentContainer .headingContainer {
  padding: 20px;
  text-align: center;
  display: table-cell;
}
.verticalVideoList .contentContainer .headingContainer img.listBadge,
.verticalVideoList .contentContainer .headingContainer h2.listHeading,
.verticalVideoList .contentContainer .headingContainer p.listDescription {
  margin-bottom: 12px;
}
.verticalVideoList .contentContainer .headingContainer img.listBadge {
  max-width: 140px;
}
.verticalVideoList .contentContainer .headingContainer h2 {
  text-align: inherit;
}
.verticalVideoList .contentContainer .carouselContainer {
  padding: 0 0 10px 20px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item {
  max-width: 250px;
  width: 250px;
  margin: 10px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer {
  width: 100%;
  margin-bottom: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer {
  -ms-flex-order: 3;
      order: 3;
  margin: 0 16px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoTitle {
  margin-bottom: 10px;
  font-weight: 600;
  overflow-wrap: break-word;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoDescription {
  margin-bottom: 10px;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer {
  position: relative;
  -ms-flex-order: 1;
      order: 1;
  margin-bottom: 16px;
  width: 100%;
  aspect-ratio: 0.5625;
  background-color: #ddd;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoThumbnail {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton {
  color: #fff;
  border-radius: 100%;
  border: 3px solid #FFF;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  padding: 15px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  line-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  transition-duration: 0.2s;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton:hover {
  border-color: #002e6d;
  background-color: #002e6d;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton:before {
  font-family: 'Icon Fonts';
  content: "\e941";
  font-size: 1.3rem;
  -webkit-text-stroke: 1.5px;
}
@media (min-width: 767px) {
  .verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton:before {
    font-size: 1.8rem;
  }
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton.instagram {
  padding: 0;
  width: 54px;
  height: 54px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton.instagram:hover,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton.instagram:focus {
  text-decoration: none;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer {
  position: absolute;
  top: 0;
  width: 100%;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 0.5625;
  margin-bottom: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.videoPlayer > * {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage {
  background-color: rgba(0, 0, 0, 0.5);
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer {
  position: absolute;
  top: 0;
  height: calc(100% - 80px);
  margin: 20px;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  background-color: white;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a {
  display: inline-block;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a:hover,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a:focus {
  text-decoration: none;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks {
  -ms-flex-order: 2;
      order: 2;
  margin: 0 16px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink {
  display: inline-block;
  text-decoration: underline;
  font-weight: 500;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  text-wrap: balance;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink:hover,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink:hover {
  text-decoration: none;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink:after,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink:after {
  display: inline-block;
  margin-left: 5px;
  font-family: 'Icon Fonts';
  content: "\e92a";
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.5625rem;
  line-height: 0.5625rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink {
  margin-bottom: 10px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink {
  margin-bottom: 20px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer a.ctaLink {
  -ms-flex-order: 4;
      order: 4;
  margin: 0 16px;
  font-weight: 600;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer.noTextContent {
  margin-bottom: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer.noTextContent .videoPlayerContainer {
  margin-bottom: 0;
}
.verticalVideoList .contentContainer .carouselContainer.singleVideo {
  padding: 0 20px 10px;
}
.verticalVideoList .contentContainer .carouselContainer.singleVideo .itemsWrapper .itemsInner {
  -ms-flex-pack: center;
      justify-content: center;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  width: 250px;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoTitle {
  width: 50%;
  height: 20px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoDescription {
  width: 100%;
  height: 40px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer {
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.pageHome .verticalVideoList {
  margin: 0;
}
