.contentListContainer .favoriteButton.buttonAsLink {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: normal;
  color: #333;
}
.contentListContainer .favoriteButton.buttonAsLink:before {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #888;
  margin-right: 8px;
}
.contentListContainer .favoriteButton.buttonAsLink.iconHeartFilled:before {
  color: #b4353d;
}
.contentListContainer .favoriteButton.buttonAsLink:hover:enabled {
  text-decoration: none;
}
