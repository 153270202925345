@media screen and (min-width:767px) {
.cgcContentList .contentListSort {
  width: 30%;
}
.cgcContentList .contentListSort .contentListDropdownButton {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  padding: 10px;
}
.cgcContentList .contentListSort .contentListDropdownButton:after {
  padding-left: 10px;
  padding-right: 0;
  font-size: 18px;
  width: 18px;
  height: 19px;
  font-weight: bold;
}
.cgcContentList .contentListSort.expanded .contentListDropdownButton:after,
.cgcContentList .contentListSort .expanded .contentListDropdownButton:after {
  padding-right: 10px;
  padding-left: 0;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer li .contentListDropdownItem,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer li .contentListDropdownItem {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 10px;
  width: calc(100% - 20px);
}

}
