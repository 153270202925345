.contentListContainer .ratingsAndReviewCount .ratingCount {
  color: #002e6d;
  vertical-align: unset;
}
.contentListContainer .carouselList.darkBlueTheme .ratingsAndReviewCount .stars:before,
.contentListContainer .carouselList.darkBlueTheme .ratingsAndReviewCount .stars:after {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .ratingsAndReviewCount .ratingCount {
  color: white;
}
