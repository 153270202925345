.contentHeader.Hero {
  margin-bottom: 0;
}
.contentHeader.Hero .heroContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 460px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.contentHeader.Hero .heroContainer .contentContainer {
  padding: 16px;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer {
  padding: 12px 24px;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 12px 0;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a {
  display: inline-block;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a {
  color: #3273f8;
  text-decoration: underline;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a:hover,
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a:focus {
  text-decoration: none;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer a.button {
  margin: 12px 0;
  padding: 8px 16px;
}
