.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage .itemTag {
  background-color: #fff9e4;
  color: #002e6d;
}
.contentListContainer .carouselList.creamTheme {
  background-color: #fff9e4;
}
.contentListContainer .carouselList.darkBlueTheme {
  background-color: #002e6d;
}
.contentListContainer .carouselList.darkBlueTheme .headingContainer {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .headingContainer h2 {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .headingContainer .descriptionContainer a {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .navigationButton {
  border-color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .navigationButton:after {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .navigationButton:focus {
  outline-color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .itemsWrapper .itemsInner .item .itemLink {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemInfo .itemTitle {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .itemsWrapper .itemsInner .item .itemLink:focus {
  outline-color: white;
}
