.linedDivider {
  border-bottom: none;
}
.linedDivider:before {
  display: none;
}
.heroTitle,
.contentTitle,
.headingLarge,
.headingMedium,
.headingSmall {
  text-transform: uppercase;
  font-weight: 900;
}
.heroTitle a,
.contentTitle a,
.headingLarge a,
.headingMedium a,
.headingSmall a {
  color: #3273f8;
  text-decoration: underline;
}
.heroTitle a:hover,
.contentTitle a:hover,
.headingLarge a:hover,
.headingMedium a:hover,
.headingSmall a:hover,
.heroTitle a:focus,
.contentTitle a:focus,
.headingLarge a:focus,
.headingMedium a:focus,
.headingSmall a:focus {
  text-decoration: none;
}
.headingLargeAlt,
.headingMediumAlt,
.headingSmallAlt {
  text-transform: none;
  font-weight: 700;
  color: #002e6d;
}
.headingLargeAlt a,
.headingMediumAlt a,
.headingSmallAlt a {
  color: #002e6d;
}
.heroTitle {
  color: #3273f8;
}
.contentTitle {
  color: #3273f8;
}
.headingSmall {
  color: #002e6d;
}
.headingSmall a {
  color: #002e6d;
}
.button,
.buttonAlt,
.buttonInverse,
.buttonCompleted,
.buttonSmall,
.buttonSmallAlt,
.buttonSmallInverse,
.buttonSmallCompleted {
  border-radius: 100px;
  text-transform: uppercase;
}
.button:focus,
.buttonAlt:focus,
.buttonInverse:focus,
.buttonCompleted:focus,
.buttonSmall:focus,
.buttonSmallAlt:focus,
.buttonSmallInverse:focus,
.buttonSmallCompleted:focus {
  border-radius: 100px;
}
.button,
.buttonAlt,
.buttonInverse,
.buttonCompleted {
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
.buttonSmall,
.buttonSmallAlt,
.buttonSmallInverse,
.buttonSmallCompleted {
  font-weight: 700;
}
.buttonAsLink {
  text-transform: none;
}
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus {
  border-radius: 100px;
}
