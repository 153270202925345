.cgcRedesignContainer .contentListContainer {
  padding: 32px 20px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  background-color: #fff9e4;
  padding: 24px;
  margin-bottom: 24px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .iconAiSummary {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 3rem;
  line-height: 3rem;
  color: #3273f8;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader {
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #3273f8;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  margin-bottom: 8px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a {
  display: inline-block;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a {
  color: #3273f8;
  text-decoration: underline;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a:hover,
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a:focus {
  text-decoration: none;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummarySubheading {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.6875rem;
  line-height: 1.125rem;
  margin-bottom: 0px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryDivider {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: #333;
  border-style: none;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryBodyText {
  color: #333;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin-bottom: 0px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListCount {
  display: none;
  margin-bottom: 16px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 16px;
  min-height: 45px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortLabel {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
  color: white;
  font-weight: 600;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown {
  position: relative;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown > .sortDropdownInnerButton {
  all: inherit;
  min-width: 200px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown > .sortDropdownInnerButton:focus {
  outline: 3px dotted #002e6d;
  outline-offset: 3px;
  border-radius: 3px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownButtonText {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  gap: 8px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 10px 12px;
  border: 1px solid #888;
  border-radius: 6px;
  background-color: white;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownButtonText:hover {
  cursor: pointer;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownButtonText:after {
  font-family: 'Icon Fonts';
  content: "\e91e";
  font-size: 12px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer {
  display: none;
  list-style-type: none;
  border: 1px solid #888;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  width: calc(100% - 2px);
  margin-top: 8px;
  z-index: 2;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer .sortDropdownListItem {
  all: inherit;
  cursor: pointer;
  padding: 8px 16px;
  width: calc(100% - 32px);
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer .sortDropdownListItem:hover,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer .sortDropdownListItem:focus {
  background-color: #f5f5f6;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort.expanded .sortDropdownButtonText:after,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .expanded .sortDropdownButtonText:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort.expanded .sortDropdownListContainer,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .expanded .sortDropdownListContainer {
  display: block;
  position: absolute;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort.expanded .sortDropdownListContainer li.selected,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .expanded .sortDropdownListContainer li.selected {
  font-weight: bold;
}
.cgcRedesignContainer .contentListContainer .emptyListContainer {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  text-align: center;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer {
  margin-top: 24px;
  text-align: center;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .skeletonPulse {
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewTitle {
  width: 100%;
  height: 26px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewRating {
  width: 30%;
  height: 20px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentText {
  width: 100%;
  height: 80px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentCardBodyFields .contentUserInfo {
  width: 75%;
  height: 22px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentActions {
  width: 100%;
  height: 40px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentFeedback {
  width: 50%;
  height: 18px;
}
