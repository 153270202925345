@media screen and (min-width:1000px) {
.searchBoxContainer {
  position: unset;
}
.searchBoxContainer:before {
  position: relative;
  top: 14px;
  left: 30px;
}

}
