.ratingAndReviewCounts .ratingCountContainer .aggregateCount:before,
.ratingAndReviewCounts .questionCountContainer .aggregateCount:before {
  display: inline-block;
  color: #002e6d;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.25rem;
  line-height: 20px;
  vertical-align: middle;
}
.ratingAndReviewCounts .ratingCountContainer a,
.ratingAndReviewCounts .questionCountContainer a {
  color: #002e6d;
  text-align: center;
}
.ratingAndReviewCounts .ratingCountContainer a:hover,
.ratingAndReviewCounts .questionCountContainer a:hover {
  color: #002e6d;
  text-decoration: none;
}
.ratingAndReviewCounts .ratingCountContainer span,
.ratingAndReviewCounts .questionCountContainer span {
  display: inline-block;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  line-height: 20px;
}
