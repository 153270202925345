.featuredContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.featuredContent.includeMargins {
  margin: 15px auto;
}
.featuredContent.imageTop {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
}
.featuredContent .textContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 24px;
  font-style: normal;
}
.featuredContent .textContainer .headerText h2 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 12px;
  text-align: left;
}
.featuredContent .textContainer .headerText h2 a {
  display: inline-block;
}
.featuredContent .textContainer .headerText h2 a {
  color: #3273f8;
  text-decoration: underline;
}
.featuredContent .textContainer .headerText h2 a:hover,
.featuredContent .textContainer .headerText h2 a:focus {
  text-decoration: none;
}
.featuredContent .textContainer p {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin-bottom: 12px;
}
.featuredContent .textContainer .appliedTextColorLinks a {
  color: inherit;
}
.featuredContent .textContainer .linkButton a {
  display: inline-block;
  color: #002e6d;
  background-color: #fff;
  border: 2px solid;
  font-weight: 600;
  line-height: 19px;
}
.featuredContent .imageContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.featuredContent .imageContainer img {
  max-width: 100%;
  height: auto;
  display: block;
}
