@media screen and (min-width:1000px) {
.featuredContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.featuredContent.imageLeft {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}
.featuredContent.imageRight {
  -ms-flex-direction: unset;
      flex-direction: unset;
}
.featuredContent .textContainer {
  padding: 24px 80px;
  -ms-flex-pack: center;
      justify-content: center;
  width: 50%;
}
.featuredContent .textContainer .headerText h2 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 12px;
}
.featuredContent .textContainer .headerText h2 a {
  display: inline-block;
}
.featuredContent .textContainer .headerText h2 a {
  color: #3273f8;
  text-decoration: underline;
}
.featuredContent .textContainer .headerText h2 a:hover,
.featuredContent .textContainer .headerText h2 a:focus {
  text-decoration: none;
}
.featuredContent .imageContainer {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
}

}
