.verticalVideoList .carouselContainer .itemsWrapper .itemsInner .item .itemContainer a.ctaLink {
  text-decoration: underline;
}
.verticalVideoList .carouselContainer .itemsWrapper .itemsInner .item .itemContainer a.ctaLink:hover {
  text-decoration: none;
}
.verticalVideoList.creamTheme {
  background-color: #fff9e4;
}
.verticalVideoList.blueTheme {
  background-color: #002e6d;
}
.verticalVideoList.blueTheme .headingContainer h2.listHeading,
.verticalVideoList.blueTheme .headingContainer p.listDescription {
  color: white;
}
.verticalVideoList.blueTheme .headingContainer h2.listHeading a,
.verticalVideoList.blueTheme .headingContainer p.listDescription a {
  color: white;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  color: #002e6d;
  background: #fff;
  border: solid 2px #002e6d;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink:hover {
  text-decoration: none;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink a {
  color: #fff;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink:focus {
  border-radius: 100px;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink:focus {
  border-radius: 100px;
}
.verticalVideoList.blueTheme .carouselContainer .navigationButton {
  border-color: white;
}
.verticalVideoList.blueTheme .carouselContainer .navigationButton:after {
  color: white;
}
.verticalVideoList.blueTheme .carouselContainer .navigationButton:focus {
  outline-color: white;
}
