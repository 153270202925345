.contentListContainer .favoriteButton.buttonAsLink {
  color: #002e6d;
  font-weight: 700;
}
.contentListContainer .favoriteButton.buttonAsLink:before {
  font-weight: normal;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink:before {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink.iconHeartFilled:before {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink:focus {
  outline-color: white;
}
