@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Agbalumo&display=swap);
label {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  display: block;
  margin-bottom: 5px;
}
.hint-text {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
}
.inputGroup {
  margin-bottom: 20px;
}
input[type="text"],
input[type="password"],
input[type="email"] {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
}
.error input[type="text"],
.error input[type="password"],
.error input[type="email"],
input[type="text"].error,
input[type="password"].error,
input[type="email"].error,
input[type="text"].alert-error,
input[type="password"].alert-error,
input[type="email"].alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error input[type="text"]:focus,
.error input[type="password"]:focus,
.error input[type="email"]:focus,
input[type="text"].error:focus,
input[type="password"].error:focus,
input[type="email"].error:focus,
input[type="text"].alert-error:focus,
input[type="password"].alert-error:focus,
input[type="email"].alert-error:focus {
  outline-width: 0;
}
input[type="number"] {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  display: inline-block;
  width: auto;
}
.error input[type="number"],
input[type="number"].error,
input[type="number"].alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error input[type="number"]:focus,
input[type="number"].error:focus,
input[type="number"].alert-error:focus {
  outline-width: 0;
}
input:invalid,
input:required {
  box-shadow: none;
}
input:disabled {
  background-color: #eee;
}
input::-webkit-input-placeholder {
  color: #aaa;
}
input:-ms-input-placeholder {
  color: #aaa;
}
input::placeholder {
  color: #aaa;
}
button,
input[type="button"],
input[type="submit"] {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  text-decoration: none;
}
button a,
input[type="button"] a,
input[type="submit"] a {
  color: #fff;
}
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus {
  border-radius: 100px;
}
button:disabled,
input[type="button"]:disabled,
input[type="submit"]:disabled {
  opacity: 0.5;
  cursor: default;
}
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
input[type="image"]:focus {
  outline: none;
}
textarea {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
}
.error textarea,
textarea.error,
textarea.alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error textarea:focus,
textarea.error:focus,
textarea.alert-error:focus {
  outline-width: 0;
}
select {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: block;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #999;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
  height: 30px;
}
.error select,
select.error,
select.alert-error {
  border-width: 2px;
  border-color: #db0038;
  padding: 4px;
}
.error select:focus,
select.error:focus,
select.alert-error:focus {
  outline-width: 0;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  vertical-align: middle;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
/* Focus Indicators */
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
a:focus {
  outline: 3px dotted #002e6d;
  outline-offset: 3px;
  border-radius: 3px;
}
input:focus,
input[type="text"]:focus,
textarea:focus {
  outline: 2px solid #002e6d;
  border: 1px solid transparent;
}

@media screen and (min-width:1000px) {
body {
  overflow-x: initial;
}
.mainContainer,
.pageContainer {
  max-width: 1200px;
  margin: auto;
}
.pageContainer {
  padding-left: 0;
  padding-right: 0;
}
.columns {
  white-space: nowrap;
}
.columns .primary,
.columns .secondary {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}
.columns .primary {
  margin: 0 10px;
  width: calc(100% - 420px);
}
.columns .secondary {
  margin-left: 20px;
  margin-right: 20px;
  width: 360px;
}
/* Temporary home page class for BC and PB - allow full width desktop items - constraint enforced within each component */
.pageHome .languageContainer,
.pageHome .mainContainer,
.pageHome .pageContainer {
  max-width: none;
}
.pageHome .pageContainer .copy,
.pageHome .pageContainer .PandoSitesScrollerList,
.pageHome .pageContainer .adChrome,
.pageHome .pageContainer .PandoSitesCategoryScrollerList .contentListItems {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.pageHome .homePage {
  padding: 0;
}
.pageHome .channelLinks.hasImages {
  margin: 10px 0 60px;
}
.pageHome .channelLinks.hasImages .channelLinksInner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

}

@media screen and (min-width:767px) {
table {
  display: table;
}
th,
td {
  padding: 4px;
  min-width: 0;
}
.pageContainer {
  padding-left: 20px;
  padding-right: 20px;
}
.mainContainer {
  margin-bottom: 40px;
}
.oneColumn {
  max-width: 748px;
  margin: auto;
}
.adhesiveAdSpacing {
  height: 130px;
}

}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  scroll-padding-top: 20px;
}
html,
body {
  overflow-x: hidden;
}
body {
  -webkit-text-size-adjust: none;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
iframe {
  display: block;
  margin: auto;
}
header,
footer,
nav,
main {
  width: 100%;
  margin: auto;
}
a {
  color: #002e6d;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}
p a {
  text-decoration: underline;
  font-weight: 600;
}
p a:hover,
p a:focus {
  text-decoration: none;
}
p {
  margin-bottom: 20px;
}
strong,
b {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}
u {
  text-decoration: underline;
}
table {
  margin-bottom: 20px;
  display: block;
  overflow-x: auto;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
th,
td {
  text-align: left;
  padding: 4px 10px;
  border: 1px solid #ccc;
  min-width: 70px;
}
hr.linedDivider {
  overflow: visible;
}
.pageContainer {
  box-sizing: border-box;
  padding: 30px 10px 0;
  width: 100%;
}
.mainContainer {
  position: relative;
}
.ui-front {
  z-index: 100;
}
.expand-desc,
.collapse-desc {
  color: #002e6d;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-transform: none;
  white-space: nowrap;
  display: none;
}
.expand-desc:hover:enabled,
.collapse-desc:hover:enabled {
  text-decoration: underline;
}
@font-face {
  font-family: 'Icon Fonts';
  src: url('/Styles/Base/Fonts/icon-fonts-v26.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[v-cloak] {
  display: none;
}
.lazyImageContainer {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.lazyImageContainer img.lazyImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@-webkit-keyframes graypulse {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #eee;
  }
  100% {
    background-color: #ddd;
  }
}
@keyframes graypulse {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #eee;
  }
  100% {
    background-color: #ddd;
  }
}
.slide-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
/* Temporary home page class for BC and PB */
.pageHome .pageContainer {
  padding: 0;
}
.pageHome .channelLinks.hasImages {
  margin: 10px 0 20px;
  border-bottom: solid 2px #f1f1f1;
}
.pageHome hr.linedDivider {
  display: none;
}
.adhesiveAdSpacing {
  display: none;
  height: 90px;
}
.adhesiveAdSpacing.visible {
  display: block;
}
.adhesiveAd .closeAdButton {
  display: none;
  background: unset;
  padding: unset;
}

/* async:start */
.iconYouTube:before {
  font-family: 'Icon Fonts';
  content: "\e900";
}
.iconWhatsApp:before {
  font-family: 'Icon Fonts';
  content: "\e901";
}
.iconTwitter:before {
  font-family: 'Icon Fonts';
  content: "\e902";
}
.iconThumbsUp:before {
  font-family: 'Icon Fonts';
  content: "\e903";
}
.iconTBSP_Print:before {
  font-family: 'Icon Fonts';
  content: "\e904";
}
.iconTBSP_Email:before {
  font-family: 'Icon Fonts';
  content: "\e905";
}
.iconTBSP_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e906";
}
.iconSearch:before {
  font-family: 'Icon Fonts';
  content: "\e92c";
}
.iconQRV_Divider:before {
  font-family: 'Icon Fonts';
  content: "\e90a";
}
.iconQRV_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e90b";
}
.iconPinterest:before {
  font-family: 'Icon Fonts';
  content: "\e90c";
}
.iconPB_Print:before {
  font-family: 'Icon Fonts';
  content: "\e90d";
}
.iconPB_Email:before {
  font-family: 'Icon Fonts';
  content: "\e90e";
}
.iconPB_Divider:before {
  font-family: 'Icon Fonts';
  content: "\e90f";
}
.iconPB_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e910";
}
.iconMobileDevice:before {
  font-family: 'Icon Fonts';
  content: "\e911";
}
.iconInstagram:before {
  font-family: 'Icon Fonts';
  content: "\e912";
}
.iconHeartCheck:before {
  font-family: 'Icon Fonts';
  content: "\e913";
}
.iconHeart:before {
  font-family: 'Icon Fonts';
  content: "\e914";
}
.iconHeartFilled:before {
  font-family: 'Icon Fonts';
  content: "\e928";
}
.iconHeartEmpty:before {
  font-family: 'Icon Fonts';
  content: "\e929";
}
.iconChevronDown:before {
  font-family: 'Icon Fonts';
  content: "\e92b";
}
.iconHalfStar:before {
  font-family: 'Icon Fonts';
  content: "\e930";
}
.iconFullStar:before {
  font-family: 'Icon Fonts';
  content: "\e92f";
}
.iconEmptyStar:before {
  font-family: 'Icon Fonts';
  content: "\e931";
}
.iconFacebook:before {
  font-family: 'Icon Fonts';
  content: "\e92d";
}
.iconCookbook:before {
  font-family: 'Icon Fonts';
  content: "\e918";
}
.iconBC_Print:before {
  font-family: 'Icon Fonts';
  content: "\e91a";
}
.iconBC_Email:before {
  font-family: 'Icon Fonts';
  content: "\e919";
}
.iconBC_Divider:before {
  font-family: 'Icon Fonts';
  content: "\e91b";
}
.iconBC_Bubble:before {
  font-family: 'Icon Fonts';
  content: "\e91c";
}
.iconAskBetty:before {
  font-family: 'Icon Fonts';
  content: "\e91d";
}
.iconLMD_Print:before {
  font-family: 'Icon Fonts';
  content: "\e91a";
}
.iconArrowBackward {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.iconArrowForward {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.iconMenuArrow:before,
.iconArrowBackward:before,
.iconArrowForward:before {
  font-family: 'Icon Fonts';
  content: "\e91e";
}
.iconUncheckedCircle:before {
  font-family: 'Icon Fonts';
  content: "\e91f";
}
.iconCheckedCircle:before {
  font-family: 'Icon Fonts';
  content: "\e920";
}
.iconTrashCan:before {
  font-family: 'Icon Fonts';
  content: "\e922";
}
.iconCloseX:before {
  font-family: 'Icon Fonts';
  content: "\e923";
}
.iconHelpToolTip:before {
  font-family: 'Icon Fonts';
  content: "\e924";
}
.iconCheckMark:before {
  font-family: 'Icon Fonts';
  content: "\e925";
}
.iconGooglePlus:before {
  font-family: 'Icon Fonts';
  content: "\e921";
}
.iconAsk:before {
  font-family: 'Icon Fonts';
  content: "\e926";
}
.iconKitchenTested {
  content: "\e927";
}
.iconKitchenTested:before {
  font-family: 'Icon Fonts';
  content: "\e927";
}
.iconArrowRight:before {
  font-family: 'Icon Fonts';
  content: "\e92a";
}
.iconQuestionAnswer:before {
  font-family: 'Icon Fonts';
  content: "\e92e";
}
.iconShop:before {
  font-family: 'Icon Fonts';
  content: "\e95e";
}
.iconCart:before {
  font-family: 'Icon Fonts';
  content: "\e93f";
}
.iconShare:before {
  font-family: 'Icon Fonts';
  content: "\e95f";
}
.iconShareAndroid:before {
  font-family: 'Icon Fonts';
  content: "\e940";
}
.iconPlayRound:before {
  font-family: 'Icon Fonts';
  content: "\e960";
}
.iconCopyLink:before {
  font-family: 'Icon Fonts';
  content: "\e961";
}
.icon-check:before {
  font-family: 'Icon Fonts';
  content: "\e932";
}
.icon-circleCheck:before {
  font-family: 'Icon Fonts';
  content: "\e933";
}
.icon-circleError:before {
  font-family: 'Icon Fonts';
  content: "\e934";
}
.icon-x:before {
  font-family: 'Icon Fonts';
  content: "\e935";
}
.iconTikTok:before {
  font-family: 'Icon Fonts';
  content: "\e936";
}
.iconRefresh:before {
  font-family: 'Icon Fonts';
  content: "\e937";
}
.iconSparkle:before {
  font-family: 'Icon Fonts';
  content: "\e939";
}
.iconSparkleDuo:before {
  font-family: 'Icon Fonts';
  content: "\e938";
}
.iconGift:before {
  font-family: 'Icon Fonts';
  content: "\e93a";
}
.iconHollowEmail:before {
  font-family: 'Icon Fonts';
  content: "\e93b";
}
.iconHollowClose:before {
  font-family: 'Icon Fonts';
  content: "\e93c";
}
.iconPlay:before {
  font-family: 'Icon Fonts';
  content: "\e93d";
}
.iconPause:before {
  font-family: 'Icon Fonts';
  content: "\e93e";
}
.iconPlayTriangle:before {
  font-family: 'Icon Fonts';
  content: "\e941";
}
.iconQRVHeartFilled:before {
  font-family: 'Icon Fonts';
  content: "\e942";
}
.iconQRVHeart:before {
  font-family: 'Icon Fonts';
  content: "\e943";
}
.iconQRVEmail:before {
  font-family: 'Icon Fonts';
  content: "\e944";
}
.iconQRVPrint:before {
  font-family: 'Icon Fonts';
  content: "\e945";
}
.iconQRVSearch:before {
  font-family: 'Icon Fonts';
  content: "\e946";
}
.iconQRVShare:before {
  font-family: 'Icon Fonts';
  content: "\e947";
}
.iconQRVShop:before {
  font-family: 'Icon Fonts';
  content: "\e948";
}
.iconQRVDoodleLeft:before {
  font-family: 'Icon Fonts';
  content: "\e949";
}
.iconQRVDoodleRight:before {
  font-family: 'Icon Fonts';
  content: "\e94a";
}
.iconDownload:before {
  font-family: 'Icon Fonts';
  content: "\e94b";
}
.iconJumpTo:before {
  font-family: 'Icon Fonts';
  content: "\e94c";
}
.iconPrint:before {
  font-family: 'Icon Fonts';
  content: "\e94d";
}
.iconAiSummary:before {
  font-family: 'Icon Fonts';
  content: "\e94e";
}
/* async:end */
.stars {
  position: relative;
  text-align: left !important;
  display: inline-block;
  line-height: 15px;
}
.stars:before,
.stars:after {
  font-family: 'Icon Fonts';
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  line-height: 20px;
  color: #002e6d;
  content: "\e931\e931\e931\e931\e931";
  letter-spacing: 1px;
}
.stars:after {
  content: "";
  position: absolute;
  left: 0;
  color: #002e6d;
}
.stars.star-10:after {
  content: "\e930";
}
.stars.star-20:after {
  content: "\e92f";
}
.stars.star-30:after {
  content: "\e92f\e930";
}
.stars.star-40:after {
  content: "\e92f\e92f";
}
.stars.star-50:after {
  content: "\e92f\e92f\e930";
}
.stars.star-60:after {
  content: "\e92f\e92f\e92f";
}
.stars.star-70:after {
  content: "\e92f\e92f\e92f\e930";
}
.stars.star-80:after {
  content: "\e92f\e92f\e92f\e92f";
}
.stars.star-90:after {
  content: "\e92f\e92f\e92f\e92f\e930";
}
.stars.star-100:after {
  content: "\e92f\e92f\e92f\e92f\e92f";
}

/* Index
   --------------------------------------------------

    0. Miscellaneous
    1. Text Headings and Copy
    2. Buttons
    3. Dividers
    4. Constraints
       
    *** NOTE ***
    Usage mixins should all be declared without parens ()
    They will then render to the stylesheet and be available for class usage
   -------------------------------------------------- */
.alert-error {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: #db0038;
}
input + .alert-error {
  margin-top: -15px;
}
.alert-success {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  color: #999;
  text-align: left;
  font-weight: normal;
  margin-bottom: 5px;
  margin-bottom: 20px;
  color: green;
}
input + .alert-success {
  margin-top: -15px;
}
.visuallyHidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(1px 1px 1px 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
}
.heroTitle {
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.75rem;
  line-height: 1.9375rem;
  margin-bottom: 20px;
}
.heroTitle a {
  display: inline-block;
}
@media (min-width: 767px) {
  .heroTitle {
    /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
    font-size: 2.25rem;
    line-height: 2.5rem;
    margin-bottom: 30px;
  }
}
.contentTitle {
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 20px;
}
.contentTitle a {
  display: inline-block;
}
.headingLarge {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
}
.headingLarge a {
  display: inline-block;
}
.headingLargeAlt {
  text-transform: uppercase;
  font-weight: 900;
  color: #3273f8;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
.headingLargeAlt a {
  color: #3273f8;
  text-decoration: underline;
}
.headingLargeAlt a:hover,
.headingLargeAlt a:focus {
  text-decoration: none;
}
.headingLargeAlt a {
  display: inline-block;
}
.headingMedium {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
}
.headingMedium a {
  display: inline-block;
}
.headingMediumAlt {
  text-transform: uppercase;
  font-weight: 900;
  color: #3273f8;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
.headingMediumAlt a {
  color: #3273f8;
  text-decoration: underline;
}
.headingMediumAlt a:hover,
.headingMediumAlt a:focus {
  text-decoration: none;
}
.headingMediumAlt a {
  display: inline-block;
}
.headingSmall {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
}
.headingSmall a {
  display: inline-block;
}
.headingSmallAlt {
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  color: #3273f8;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
.headingSmallAlt a {
  color: #3273f8;
  text-decoration: underline;
}
.headingSmallAlt a:hover,
.headingSmallAlt a:focus {
  text-decoration: none;
}
.headingSmallAlt a {
  color: #002e6d;
}
.headingSmallAlt a {
  display: inline-block;
}
.bodyCopyLarge {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.bodyCopyMedium {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.bodyCopyMediumAlt {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
}
.bodyCopySmall {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.bodyCopySmallAlt {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
}
.bodyCopyXSmall {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
}
.bodyCopyXXSmall {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.625rem;
  line-height: 0.875rem;
}
.footerCopy {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
.button {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
}
.button:hover {
  text-decoration: none;
}
.button a {
  color: #fff;
}
.buttonAlt {
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  background: #002e6d;
}
.buttonAlt:focus {
  border-radius: 100px;
}
.buttonAlt:hover {
  text-decoration: none;
}
.buttonAlt a {
  color: #fff;
}
.buttonInverse {
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  color: #002e6d;
  background: #fff;
  border: solid 2px #002e6d;
}
.buttonInverse:focus {
  border-radius: 100px;
}
.buttonInverse:hover {
  text-decoration: none;
}
.buttonInverse a {
  color: #fff;
}
.buttonCompleted {
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  border: solid 1px #ccc;
  padding-top: 9px;
  padding-bottom: 9px;
  background: #fff;
  color: #666;
  text-decoration: none;
  cursor: default;
}
.buttonCompleted:focus {
  border-radius: 100px;
}
.buttonCompleted:hover {
  text-decoration: none;
}
.buttonCompleted a {
  color: #fff;
}
.buttonCompleted:before {
  font-family: 'Icon Fonts';
  content: "\e925";
  margin-right: 5px;
}
.buttonSmall {
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  vertical-align: middle;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  padding: 6px 10px;
}
.buttonSmall:focus {
  border-radius: 100px;
}
.buttonSmall:hover {
  text-decoration: none;
}
.buttonSmall a {
  color: #fff;
}
.buttonSmallAlt {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  vertical-align: middle;
  background: #002e6d;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  padding: 6px 10px;
}
.buttonSmallAlt:focus {
  border-radius: 100px;
}
.buttonSmallAlt:focus {
  border-radius: 100px;
}
.buttonSmallAlt:hover {
  text-decoration: none;
}
.buttonSmallAlt a {
  color: #fff;
}
.buttonSmallInverse {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  color: #fff;
  vertical-align: middle;
  background: #fff;
  border: solid 2px #002e6d;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
  padding: 4px 10px;
  color: #002e6d;
}
.buttonSmallInverse:focus {
  border-radius: 100px;
}
.buttonSmallInverse:focus {
  border-radius: 100px;
}
.buttonSmallInverse:hover {
  text-decoration: none;
}
.buttonSmallInverse a {
  color: #fff;
}
.buttonSmallCompleted {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  color: #fff;
  vertical-align: middle;
  border: solid 1px #ccc;
  padding-top: 9px;
  padding-bottom: 9px;
  background: #fff;
  text-decoration: none;
  cursor: default;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
  color: #666;
  padding: 6px 10px;
}
.buttonSmallCompleted:focus {
  border-radius: 100px;
}
.buttonSmallCompleted:focus {
  border-radius: 100px;
}
.buttonSmallCompleted:hover {
  text-decoration: none;
}
.buttonSmallCompleted a {
  color: #fff;
}
.buttonSmallCompleted:before {
  font-family: 'Icon Fonts';
  content: "\e925";
  margin-right: 5px;
}
.buttonDisabled {
  opacity: 0.5;
  cursor: default;
}
.buttonCancel {
  color: #002e6d;
  background-color: transparent;
}
.buttonCancel:hover:enabled {
  text-decoration: underline;
}
.buttonAsLink {
  color: #002e6d;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.buttonAsLink:hover:enabled {
  text-decoration: underline;
}
.skipToContentLink {
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.skipToContentLink:focus {
  border-radius: 100px;
}
.skipToContentLink:hover {
  text-decoration: none;
}
.skipToContentLink a {
  color: #fff;
}
.skipToContentLink:focus {
  z-index: 1000;
  margin: 2px;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.divider {
  margin-bottom: 40px;
}
.linedDivider {
  margin-top: 40px;
  margin-bottom: 40px;
  border-style: none;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.linedDivider:before {
  font-family: 'Icon Fonts';
  background-color: #fff;
  color: #ccc;
  display: block;
  left: 50%;
  position: absolute;
  text-align: center;
  z-index: 2;
}
@media (min-width: 767px) {
  .linedDivider {
    border-bottom-width: 2px;
  }
  .linedDivider:before {
    -webkit-transform: scale(2);
            transform: scale(2);
  }
}
.constrained {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.constrainedNarrow {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.linedDivider {
  border-bottom: none;
}
.linedDivider:before {
  display: none;
}
.heroTitle,
.contentTitle,
.headingLarge,
.headingMedium,
.headingSmall {
  text-transform: uppercase;
  font-weight: 900;
}
.heroTitle a,
.contentTitle a,
.headingLarge a,
.headingMedium a,
.headingSmall a {
  color: #3273f8;
  text-decoration: underline;
}
.heroTitle a:hover,
.contentTitle a:hover,
.headingLarge a:hover,
.headingMedium a:hover,
.headingSmall a:hover,
.heroTitle a:focus,
.contentTitle a:focus,
.headingLarge a:focus,
.headingMedium a:focus,
.headingSmall a:focus {
  text-decoration: none;
}
.headingLargeAlt,
.headingMediumAlt,
.headingSmallAlt {
  text-transform: none;
  font-weight: 700;
  color: #002e6d;
}
.headingLargeAlt a,
.headingMediumAlt a,
.headingSmallAlt a {
  color: #002e6d;
}
.heroTitle {
  color: #3273f8;
}
.contentTitle {
  color: #3273f8;
}
.headingSmall {
  color: #002e6d;
}
.headingSmall a {
  color: #002e6d;
}
.button,
.buttonAlt,
.buttonInverse,
.buttonCompleted,
.buttonSmall,
.buttonSmallAlt,
.buttonSmallInverse,
.buttonSmallCompleted {
  border-radius: 100px;
  text-transform: uppercase;
}
.button:focus,
.buttonAlt:focus,
.buttonInverse:focus,
.buttonCompleted:focus,
.buttonSmall:focus,
.buttonSmallAlt:focus,
.buttonSmallInverse:focus,
.buttonSmallCompleted:focus {
  border-radius: 100px;
}
.button,
.buttonAlt,
.buttonInverse,
.buttonCompleted {
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
.buttonSmall,
.buttonSmallAlt,
.buttonSmallInverse,
.buttonSmallCompleted {
  font-weight: 700;
}
.buttonAsLink {
  text-transform: none;
}
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus {
  border-radius: 100px;
}



hr.linedDivider,
.linedDivider {
  display: none;
}

@media screen and (min-width:1000px) {
/* async:start */
.footerLinksContainer {
  padding: 40px 20px 80px;
}
.footerLinksContainer .footerLinks {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.footerLinksContainer .footerLinks .footerLinkSet {
  -ms-flex: 1 1 20%;
      flex: 1 1 20%;
  text-align: left;
  margin-bottom: 0;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList {
  display: block;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem {
  padding: 0;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink {
  line-height: 3;
  padding: 0;
}
.footerLinksContainer .footerLinks .copyright {
  -ms-flex: 1 1 40%;
      flex: 1 1 40%;
  text-align: right;
}
.footerLinksContainer .footerLinks .bottomLinks .footerLinkList {
  display: -ms-flexbox;
  display: flex;
}
.footerLinksContainer .footerLinks .bottomLinks .footerLinkList .footerLinkItem {
  padding: 0 7px;
}
/* async:end */

}

/* async:start */
footer {
  width: 100%;
}
.footerContainer {
  width: 100%;
  text-align: center;
}
.footerSocialContainer {
  padding: 60px 0;
  background: #002e6d;
}
.footerLinksContainer {
  padding: 40px 20px;
  background: #002e6d;
}
.footerLinksContainer .footerLinks {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.footerLinksContainer .footerLinks .footerLinkSet {
  margin-bottom: 40px;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerHeading {
  font-family: 'Poppins', sans-serif;
  color: #fff;
  margin-bottom: 10px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.375rem;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem {
  padding: 0 7px;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  line-height: 2;
  display: inline-block;
  padding: 4px;
  text-decoration: underline;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink.privacyChoices .footerLinkImage {
  height: 14px;
  margin-left: 4px;
  margin-bottom: -2px;
  display: inline-block;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink:hover,
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink:focus {
  text-decoration: none;
}
.footerLinksContainer .footerLinks .truste {
  margin-top: 5px;
}
.footerLinksContainer .footerLinks .copyright {
  font-family: 'Poppins', sans-serif;
  color: #fff;
}
.footerLinksContainer .footerLinks .bottomLinks {
  padding-top: 40px;
}
/* async:end */

@media screen and (min-width:1000px) {
html {
  scroll-padding-top: 120px;
}
.unsetScrollPadding {
  scroll-padding-top: unset;
}
header {
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  max-width: 100%;
  z-index: 3;
}
header .headerContainer {
  max-width: 100%;
  padding: 10px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: nowrap;
      flex-flow: nowrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
header .headerContainer .logoContainer {
  margin: 0;
  margin-right: 10px;
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
}
header .headerContainer .headerSearch {
  -ms-flex-order: 2;
      order: 2;
  max-width: 725px;
}
header .headerContainer .userLinks {
  -ms-flex-order: 2;
      order: 2;
  -ms-flex: 1 1 0px;
      flex: 1 1 0px;
  margin: 10px 0 10px 10px;
}
header .language .languageContainer {
  padding: 10px 20px;
}
.adhesiveHeaderAdFixed header {
  top: 130px;
}

}

header {
  background: #002e6d;
  position: relative;
}
header .headerContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
      flex-flow: wrap;
  -ms-flex-align: center;
      align-items: center;
}
header .headerContainer .logoContainer {
  -ms-flex-order: 1;
      order: 1;
  margin: 10px 0 10px 10px;
}
header .headerContainer .userLinks {
  -ms-flex-order: 2;
      order: 2;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: right;
      justify-content: right;
  margin: 10px;
}
header .headerContainer .simpleNavToggle {
  -ms-flex-order: 3;
      order: 3;
  margin: 10px 10px 10px 0;
}
header .headerContainer .headerSearch {
  -ms-flex-order: 4;
      order: 4;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
header .language {
  background-color: #002e6d;
}
header .language .languageContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  z-index: 2;
  padding: 8px;
}
header .language .languageContainer a {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: normal;
  text-decoration: underline;
  color: #fff;
}
header .language .languageContainer a:hover,
header .language .languageContainer a:focus {
  text-decoration: none;
}

@media screen and (min-width:1000px) {
.headerLogo {
  top: 15px;
  left: 20px;
  width: 160px;
  height: 60px;
}

}

.headerLogo {
  display: block;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 40px;
  background: url('/Styles/Themes/Pando/Images/pando-site-logo.svg') no-repeat;
  background-size: cover;
}

@media screen and (min-width:1000px) {
/* async:start */
.footerSocialContainer .footerSocial {
  -ms-flex-direction: row;
      flex-direction: row;
}
.footerSocialContainer .footerSocial .footerLogo {
  margin: 0;
  width: 120px;
  height: 118px;
}
/* async:end */

}

/* async:start */
.footerSocialContainer {
  padding: 50px 20px 0;
}
.footerSocialContainer .footerSocial {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: column;
      flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 25px;
  border-bottom: 2px solid #fff;
}
.footerSocialContainer .footerSocial .footerLogo {
  background: url(/Styles/Themes/PB/Images/img-logo.svg) no-repeat;
  background-size: cover;
  margin: 0 0 25px;
  display: block;
  width: 111px;
  height: 110px;
}
.footerSocialContainer .socialLinks .channelLinks {
  background-color: #002e6d;
}
.footerSocialContainer .socialLinks .channelLinks li a {
  background-color: #002e6d;
}
.footerSocialContainer .socialLinks .channelLinks li.iconFacebook a::before,
.footerSocialContainer .socialLinks .channelLinks li.iconPinterest a::before,
.footerSocialContainer .socialLinks .channelLinks li.iconTikTok a::before {
  color: #fff;
}
.footerSocialContainer .socialLinks .channelLinks li.iconInstagram a::before,
.footerSocialContainer .socialLinks .channelLinks li.iconYouTube a::before {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink {
  font-weight: bold;
}
.footerLinksContainer .footerLinks .footerLinkSet .footerLinkList .footerLinkItem .footerLink:focus {
  outline-color: white;
}
.footerLinksContainer .footerLinks .copyright {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
/* async:end */

@media screen and (min-width:1000px) {
.headerLogo {
  width: 65px;
  height: 65px;
  background-size: 65px;
}

}

.headerLogo {
  display: block;
  width: 55px;
  height: 55px;
  background: url('/Styles/Themes/PB/Images/img-logo.svg') center / 55px no-repeat;
}
.headerLogo:focus {
  outline-color: #fff;
}

.contentHeader.Hero {
  margin-bottom: 0;
}
.contentHeader.Hero .heroContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 460px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.contentHeader.Hero .heroContainer .contentContainer {
  padding: 16px;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer {
  padding: 12px 24px;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin: 12px 0;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a {
  display: inline-block;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a {
  color: #3273f8;
  text-decoration: underline;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a:hover,
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 a:focus {
  text-decoration: none;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer a.button {
  margin: 12px 0;
  padding: 8px 16px;
}

.contentHeader.Hero .heroContainer .contentContainer .textContainer {
  border-radius: 20px;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseTextTheme h2 {
  color: white;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseTextTheme a.button:focus {
  outline-color: white;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseFullTheme h2 {
  color: white;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseFullTheme a.button {
  color: #002e6d;
  background: #fff;
}
.contentHeader.Hero .heroContainer .contentContainer .textContainer.inverseFullTheme a.button:focus {
  outline-color: white;
}

@media screen and (min-width:767px) {
.contentHeader.Hero .heroContainer .contentContainer .textContainer h2 {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 2.25rem;
  line-height: 2.5rem;
}

}

@media screen and (min-width:1000px) {
.contentHeader.Hero .heroContainer {
  -ms-flex-direction: row;
      flex-direction: row;
  height: 41.09vw;
  padding: 0 40px;
  background-size: 100%;
}
.contentHeader.Hero .heroContainer .contentContainer {
  width: 33.33%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 0;
}

}

.searchBoxContainer {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  background-color: #002557;
  padding: 10px;
}
.searchBoxContainer:before {
  position: absolute;
  z-index: 1;
  top: 22px;
  left: 22px;
  font-family: 'Icon Fonts';
  content: "\e92c";
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer {
  position: relative;
  display: inherit;
  width: 100%;
}
.searchBoxContainer .searchTermContainer input.searchInput {
  width: 100%;
  padding: 10px 38px 10px 38px;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #333;
  background-color: #fff;
  border: 2px solid #002557;
  border-radius: 100px 0 0 100px;
  border-right-width: 1px;
}
.searchBoxContainer .searchTermContainer input.searchInput::-webkit-input-placeholder {
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer input.searchInput:-ms-input-placeholder {
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer input.searchInput::placeholder {
  color: #002e6d;
}
.searchBoxContainer .searchTermContainer input.searchInput::-ms-clear {
  display: none;
}
.searchBoxContainer .searchTermContainer input.searchInput:focus {
  outline: 2px dotted #002e6d;
  outline-offset: -8px;
  border-radius: 100px 0 0 100px;
}
.searchBoxContainer .searchTermContainer .clearInputButton {
  position: absolute;
  width: 20px;
  height: 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0px;
  background: none;
  top: calc(50% - 10px);
  right: 12px;
  color: #002e6d;
}
.searchBoxContainer button.searchLinkButton {
  padding: 12px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  font-weight: 700;
  white-space: nowrap;
  background-color: #fff9e4;
  color: #002e6d;
  border: 2px solid #002557;
  border-radius: 0 100px 100px 0;
  border-left-width: 1px;
}
.searchBoxContainer button.searchLinkButton:hover {
  text-decoration: none;
}
.searchBoxContainer button.searchLinkButton:focus {
  outline: 2px dotted #002e6d;
  outline-offset: -8px;
  border-radius: 0 100px 100px 0;
}

.searchBoxContainer button.searchLinkButton {
  font-weight: 900;
}

@media screen and (min-width:1000px) {
.searchBoxContainer {
  padding: 0px;
  background: none;
}
.searchBoxContainer:before {
  top: 12px;
  left: 12px;
}
.searchBoxContainer button.searchLinkButton {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.125rem;
}

}

@media screen and (min-width:1000px) {
.searchBoxContainer {
  position: unset;
}
.searchBoxContainer:before {
  position: relative;
  top: 14px;
  left: 30px;
}

}

.simpleNavToggle {
  position: relative;
  padding: 24px 5px 5px;
  background: #fff;
  color: #002e6d;
  border-radius: 5px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.simpleNavToggle span {
  opacity: 1;
  transition: opacity 0.2s;
}
.simpleNavToggle:hover {
  text-decoration: none;
}
.simpleNavToggle:before,
.simpleNavToggle:after {
  content: '';
  background-color: #002e6d;
  position: absolute;
  display: block;
  left: 10px;
  height: 2px;
  width: 24px;
  transition: all 0.2s;
}
.simpleNavToggle:before {
  top: 6px;
}
.simpleNavToggle:after {
  top: 14px;
}
.simpleNavToggle.opened span {
  opacity: 0;
  transition: opacity 0.1s;
}
.simpleNavToggle.opened:before {
  left: 11px;
  width: 25px;
  top: 8px;
  -webkit-transform: translate(-2px, 11px) rotate(45deg);
          transform: translate(-2px, 11px) rotate(45deg);
}
.simpleNavToggle.opened:after {
  left: 11px;
  width: 25px;
  top: 16px;
  -webkit-transform: translate(-2px, 3px) rotate(-45deg);
          transform: translate(-2px, 3px) rotate(-45deg);
}
.targetedNavigation {
  display: block;
  position: absolute;
  top: 65px;
  bottom: 0;
  overflow: visible;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  z-index: 4;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
}
.targetedNavigation.opened {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.targetedNavigation .levelOneItems {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: #002e6d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.targetedNavigation .levelOneItems li {
  position: relative;
  padding: 0 10px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 2rem;
  font-family: 'Poppins', sans-serif;
  background: #fff;
  overflow: hidden;
  border-radius: 4px;
}
.targetedNavigation .levelOneItems li a {
  display: block;
  position: relative;
  padding: 10px 0;
  color: #3273f8;
}
.targetedNavigation .levelOneItems li a:focus {
  outline-offset: -3px;
}
.targetedNavigation .levelOneItems li.withChildren:before,
.targetedNavigation .levelOneItems li.withChildren:after {
  content: '';
  position: absolute;
  display: inline-block;
  height: 2px;
  width: 10px;
  vertical-align: middle;
  right: 15px;
  top: 22px;
  background: #3273f8;
}
.targetedNavigation .levelOneItems li.withChildren:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.targetedNavigation .levelOneItems li.withChildren.expanded {
  padding-bottom: 15px;
}
.targetedNavigation .levelOneItems li.withChildren.expanded:after {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.targetedNavigation .levelOneItems li.withChildren a,
.targetedNavigation .levelOneItems li.withChildren span {
  margin-right: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.targetedNavigation .levelOneItems li.withChildren .listSection {
  display: none;
}
.targetedNavigation .levelOneItems li .subNavDisplay .subNavSection {
  padding-top: 10px;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  gap: 20px;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li a {
  color: #002e6d;
  margin: 0;
  padding: 0;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li a:after {
  border-left-width: 0;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li .levelThreeItems {
  max-height: 0;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li:before,
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li:after {
  background: none;
}
.targetedNavigation .levelOneItems li .subNavDisplay .levelTwoItems li:last-child {
  border: 0;
}
.subNavDisplay {
  max-height: 0;
  transition: max-height 0.2s linear;
  z-index: 4;
}
.expanded > .subNavDisplay {
  overflow-y: hidden;
  max-height: none;
  transition: max-height 0.2s linear;
}
/* async:start */
li.newNavigationItem a.mainLink,
li.bakeOffContestItem a.mainLink {
  position: relative;
}
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  content: "new!";
  position: absolute;
  top: 0;
  left: 0;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #002e6d;
}
.fr-CA li.newNavigationItem a.mainLink::before {
  content: "nouveau!";
}
.es-ES li.newNavigationItem a.mainLink::before {
  content: "nuevo!";
}
li.bakeOffContestItem a.mainLink::before {
  content: "It's Back!";
}
/* async:end */

.targetedNavigation {
  top: 75px;
}
.targetedNavigation .levelOneItems li {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 2rem;
}
.targetedNavigation .levelOneItems li a {
  font-weight: 900;
  text-transform: uppercase;
}
.targetedNavigation .levelOneItems li .subNavDisplay li a {
  font-weight: 700;
  text-transform: none;
}
/* async:start */
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  font-family: 'Agbalumo', sans-serif;
  color: #002e6d;
  text-transform: none;
  font-weight: normal;
}
/* async:end */

@media screen and (min-width:1000px) {
.simpleNavToggle {
  display: none;
}
.targetedNavigation {
  display: block;
  background: #002557;
  overflow: visible;
  position: relative;
  top: 0;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
  padding: 10px 20px;
  width: calc(100% - 40px);
}
.targetedNavigation .levelOneItems {
  position: static;
  background: inherit;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  gap: 20px;
}
.targetedNavigation .levelOneItems li a:focus {
  outline-offset: 3px;
}
.targetedNavigation .levelOneItems > li,
.targetedNavigation .levelOneItems > li.withChildren {
  position: static;
  -ms-flex-item-align: center;
      align-self: center;
  padding: 0;
  background: #002557;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  overflow: visible;
  border-radius: 0;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.targetedNavigation .levelOneItems > li:before,
.targetedNavigation .levelOneItems > li.withChildren:before,
.targetedNavigation .levelOneItems > li:after,
.targetedNavigation .levelOneItems > li.withChildren:after {
  width: 0;
  height: 0;
  background: none;
}
.targetedNavigation .levelOneItems > li > a,
.targetedNavigation .levelOneItems > li.withChildren > a,
.targetedNavigation .levelOneItems > li > span,
.targetedNavigation .levelOneItems > li.withChildren > span {
  color: #fff;
  display: inline-block;
  margin: 0;
  text-align: center;
  height: 100%;
  padding: 0px;
  line-height: 30px;
  text-decoration: none;
  white-space: nowrap;
}
.targetedNavigation .levelOneItems > li:hover,
.targetedNavigation .levelOneItems > li.withChildren:hover {
  border-bottom: 2px solid #fff;
}
.targetedNavigation .levelOneItems > li:hover > a,
.targetedNavigation .levelOneItems > li.withChildren:hover > a {
  color: #fff;
}
.targetedNavigation .levelOneItems li.withChildren a {
  overflow: visible;
  white-space: normal;
}
.targetedNavigation .levelOneItems li.withChildren a:after {
  border: none;
}
.targetedNavigation .levelOneItems li.withChildren > a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.targetedNavigation .levelOneItems li.withChildren > a:after {
  position: static;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.5rem;
  line-height: 0.5rem;
  font-family: 'Icon Fonts';
  content: "\e92b";
  padding: 0 3px;
  transition-duration: 0.2s;
}
.targetedNavigation .levelOneItems li.withChildren.expanded {
  padding-bottom: 0;
}
.targetedNavigation .levelOneItems li.withChildren.expanded > a {
  color: #fff;
}
.targetedNavigation .levelOneItems li.withChildren.expanded > a:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition-duration: 0.2s;
}
.levelOneItems li.withChildren .subNavDisplay {
  top: 54px;
  max-height: none;
  padding: 16px 10%;
  background: #fff;
  box-sizing: border-box;
  color: #3273f8;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  left: 0;
  right: 0;
  position: absolute;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
  display: none;
}
.levelOneItems li.withChildren .subNavDisplay .listSection {
  display: block;
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
}
.levelOneItems li.withChildren .subNavDisplay .subNavSection {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  padding-top: 0;
}
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection ~ .subNavSection {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection ~ .subNavSection .levelTwoItems > li {
  -ms-flex: 0 0 33%;
      flex: 0 0 33%;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 0;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li {
  position: relative;
  padding: 20px;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #fff;
  white-space: normal;
  box-sizing: border-box;
  overflow: hidden;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li > a,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li > a,
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li > span,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li > span {
  display: block;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li > a:after,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li > a:after {
  content: "\2192";
  position: static;
  display: inline;
  margin-left: 5px;
  vertical-align: baseline;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems {
  margin-top: 5px;
  display: block;
  max-height: none;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems li,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems li {
  background: #fff;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems li a,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems li a {
  font-weight: normal;
  margin: 5px 0;
  color: #002e6d;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.375rem;
}
.levelOneItems li.withChildren .subNavDisplay .listSection .levelTwoItems > li ul.levelThreeItems li a:focus,
.levelOneItems li.withChildren .subNavDisplay .subNavSection .levelTwoItems > li ul.levelThreeItems li a:focus {
  outline-offset: 0;
}
.levelOneItems li.withChildren.expanded .subNavDisplay {
  display: -ms-flexbox;
  display: flex;
}
/* async:start */
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  top: -10px;
}
/* async:end */

}

@media screen and (min-width:1000px) {
.targetedNavigation .levelOneItems > li,
.targetedNavigation .levelOneItems > li.withChildren {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.875rem;
}
.targetedNavigation .levelOneItems > li a,
.targetedNavigation .levelOneItems > li.withChildren a {
  text-transform: unset;
}
.targetedNavigation .levelOneItems > li a:focus,
.targetedNavigation .levelOneItems > li.withChildren a:focus {
  outline-color: white;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > a,
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > span {
  font-weight: 900;
  text-transform: uppercase;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > a:focus,
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li > span:focus {
  outline-color: #002e6d;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li ul.levelThreeItems li a {
  font-weight: 700;
}
.targetedNavigation .levelOneItems li.withChildren .subNavDisplay .levelTwoItems > li ul.levelThreeItems li a:focus {
  outline-color: #002e6d;
}
/* async:start */
li.newNavigationItem a.mainLink:before,
li.bakeOffContestItem a.mainLink:before {
  color: #ff9404;
}
/* async:end */

}

.userLinks {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  color: #fff;
  z-index: 5;
}
.userLinks a,
.userLinks input {
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.userLinks .headerLogin a,
.userLinks .headerMyAccount a,
.userLinks .headerLogin input,
.userLinks .headerMyAccount input {
  display: inline-block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.userLinks .headerLogin a:hover,
.userLinks .headerMyAccount a:hover,
.userLinks .headerLogin input:hover,
.userLinks .headerMyAccount input:hover,
.userLinks .headerLogin a:focus,
.userLinks .headerMyAccount a:focus,
.userLinks .headerLogin input:focus,
.userLinks .headerMyAccount input:focus {
  text-decoration: none;
}
.userLinks .headerAnonymous .headerLogin {
  display: -ms-flexbox;
  display: flex;
}
.userLinks .headerAnonymous .headerLogin li {
  margin-left: 10px;
}
.userLinks .headerAnonymous .headerLogin li a {
  padding: 9px 10px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  border: 2px solid #fff;
  border-radius: 6px;
}
.userLinks .headerAnonymous .headerLogin li a.signUpButton {
  background-color: #fff;
  color: #002e6d;
}
.userLinks .headerAnonymous .headerLogin li a.logInButton {
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
}
.userLinks .headerAuthenticated {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 10px;
}
.userLinks .headerAuthenticated .headerMyAccount {
  position: relative;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount {
  all: inherit;
  display: inline-block;
  padding: 7px 15px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount:after {
  display: inline-block;
  font-size: 8px;
  vertical-align: middle;
  margin-left: 5px;
  padding-top: 2px;
  font-weight: 600;
  transition-duration: 0.2s;
  font-family: 'Icon Fonts';
  content: "\e92b";
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount:focus {
  outline: 3px dotted #002e6d;
  outline-offset: 3px;
  border-radius: 3px;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop {
  display: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
.userLinks .headerAuthenticated .favoritesButton {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 100px;
}
.userLinks .headerAuthenticated .favoritesButton:before {
  display: block;
  content: '';
  height: 17px;
  width: 20px;
  background: url('/Styles/Base/Images/favorites-icon.svg') center / 20px no-repeat;
}
.userLinks .headerAuthenticated .favoritesButton span {
  margin-left: 6px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: #002e6d;
}
.userLinks .headerAuthenticated .favoritesButton:hover,
.userLinks .headerAuthenticated .favoritesButton:focus {
  text-decoration: none;
}
/* async:start */
.userLinks .headerAuthenticated .headerMyAccount ul.drop {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background: #fff;
  padding-bottom: 7px;
  border-radius: 0 0 4px 4px;
  z-index: 2;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button {
  display: block;
  padding: 7px 15px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1.375rem;
  font-weight: 600;
  color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li #logout {
  background: none;
  border-radius: 0;
  width: -webkit-fill-available;
  text-align: left;
  color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded {
  background: #fff;
  border-radius: 4px 4px 0 0;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded ul.drop {
  display: block;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded button.myAccount {
  color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded button.myAccount:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition-duration: 0.2s;
  padding-top: 2px;
  color: #002e6d;
}
/* async:end */

.userLinks .headerAnonymous .headerLogin li a {
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 100px;
  font-weight: 900;
}
.userLinks .headerAnonymous .headerLogin li a:focus {
  outline-color: white;
}
.userLinks .headerAuthenticated {
  font-weight: 700;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount:focus {
  outline-color: white;
}
.userLinks .headerAuthenticated .favoritesButton:focus {
  outline-color: white;
}
/* async:start */
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button {
  font-weight: 700;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a:focus,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button:focus {
  outline-color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li #logout {
  text-transform: none;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li #logout:focus {
  outline-color: #002e6d;
}
.userLinks .headerAuthenticated .headerMyAccount.expanded button.myAccount:focus {
  outline-color: #002e6d;
}
/* async:end */

@media screen and (min-width:1000px) {
.userLinks .headerAnonymous .headerLogin li a {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.125rem;
  white-space: nowrap;
}
.userLinks .headerAuthenticated .headerMyAccount button.myAccount {
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.875rem;
  white-space: nowrap;
}
.userLinks .headerAuthenticated .favoritesButton {
  padding: 10px 15px;
}
.userLinks .headerAuthenticated .favoritesButton:before {
  height: 24px;
  width: 28px;
  background-size: 28px;
}
.userLinks .headerAuthenticated .favoritesButton span {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.875rem;
}
/* async:start */
.userLinks .headerAuthenticated .headerMyAccount ul.drop {
  padding-bottom: 10px;
}
.userLinks .headerAuthenticated .headerMyAccount ul.drop li a,
.userLinks .headerAuthenticated .headerMyAccount ul.drop li button {
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.375rem;
}
/* async:end */

}

.channelLinks {
  margin: -30px -10px 0;
  white-space: nowrap;
  position: relative;
  border-bottom: 1px solid #f5f5f6;
}
.channelLinks:after {
  content: ' ';
  overflow: hidden;
  position: absolute;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
.channelLinks .channelLinksInner {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.channelLinks .channelLinksInner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.channelLinks h3 {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 15px;
}
.channelLinks ul {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}
.channelLinks li {
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
  color: #002e6d;
  padding: 15px 5px;
}
.channelLinks li:after {
  content: "|";
  padding: 0 8px;
}
.channelLinks li:last-child {
  padding-right: 30px;
}
.channelLinks li:last-child:after {
  content: "";
  padding: 0;
}
.channelLinks.hasImages {
  white-space: initial;
}
.channelLinks.hasImages:after {
  display: none;
}
.channelLinks.hasImages h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  margin-bottom: 0;
  text-align: left;
  width: 20%;
}
.channelLinks.hasImages h3 a {
  display: inline-block;
}
.channelLinks.hasImages h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.channelLinks.hasImages h3 a:hover,
.channelLinks.hasImages h3 a:focus {
  text-decoration: none;
}
.channelLinks.hasImages h3 a {
  color: #002e6d;
}
.channelLinks.hasImages li {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  width: 30%;
  margin-right: 5px;
}
.channelLinks.hasImages li a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.channelLinks.hasImages li a .linkTitle {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  line-height: 1.25;
  padding-left: 10px;
  text-align: left;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.channelLinks.hasImages li a img {
  -ms-flex: 0 0 90px;
      flex: 0 0 90px;
  max-width: 90px;
  -ms-flex-order: -1;
      order: -1;
}
.channelLinks.hasImages li:after {
  display: none;
}
/* async:start */
.socialLinks .channelLinks {
  margin: 0;
  border-bottom: none;
}
.socialLinks .channelLinks:after {
  display: none;
}
.socialLinks .channelLinks .channelLinksInner {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}
.socialLinks .channelLinks h3 {
  color: inherit;
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  color: #fff;
  width: 100%;
}
.socialLinks .channelLinks h3 a {
  display: inline-block;
}
.socialLinks .channelLinks h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.socialLinks .channelLinks h3 a:hover,
.socialLinks .channelLinks h3 a:focus {
  text-decoration: none;
}
.socialLinks .channelLinks h3 a {
  color: #002e6d;
}
.socialLinks .channelLinks ul {
  overflow-x: visible;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.socialLinks .channelLinks li {
  padding: 0 12px;
}
.socialLinks .channelLinks li:before {
  display: none;
}
.socialLinks .channelLinks li:last-child {
  padding-right: 12px;
}
.socialLinks .channelLinks li:after {
  content: "";
  padding: 0;
}
.socialLinks .channelLinks li a {
  display: inline-block;
  background: white;
}
.socialLinks .channelLinks li a:before {
  font-family: 'Icon Fonts';
  font-size: 1.75rem;
  line-height: 40px;
  color: #555;
  margin: auto;
}
.socialLinks .channelLinks li a:hover,
.socialLinks .channelLinks li a:focus {
  text-decoration: none;
}
.socialLinks .channelLinks li.iconFacebook a:before {
  content: "\e92d";
  color: #3b5a99;
}
.socialLinks .channelLinks li.iconPinterest a:before {
  content: "\e90c";
  color: #bb171f;
}
.socialLinks .channelLinks li.iconInstagram a:before {
  content: "\e912";
  color: #dc2743;
  background-image: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.socialLinks .channelLinks li.iconTwitter a:before {
  content: "\e902";
  color: #00acec;
}
.socialLinks .channelLinks li.iconYouTube a:before {
  content: "\e900";
  color: #e32d28;
  background: -webkit-linear-gradient(#e32d28, #c0191f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.socialLinks .channelLinks li.iconTikTok a:before {
  content: "\e936";
  color: #000000;
}
/* async:end */

.channelLinks {
  background-color: #3273f8;
  margin-bottom: 20px;
  border-bottom: 0;
}
.channelLinks:after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #3273f8);
}
.channelLinks h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #002e6d;
  color: #fff;
  margin-bottom: 0;
}
.channelLinks h3 a {
  display: inline-block;
}
.channelLinks h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.channelLinks h3 a:hover,
.channelLinks h3 a:focus {
  text-decoration: none;
}
.channelLinks h3 a {
  color: #002e6d;
}
.channelLinks a {
  color: #fff;
}
.channelLinks.hasImages h3 {
  color: #fff;
}
/* async:start */
.socialLinks .channelLinks {
  background-color: #fff;
}
.socialLinks .channelLinks h3 {
  color: #3273f8;
}
.socialLinks .channelLinks li a:before {
  font-size: 2rem;
}
/* async:end */

@media screen and (min-width:767px) {
.channelLinks.hasImages li {
  width: 40%;
}
.channelLinks.hasImages li a img {
  -ms-flex: 0 0 100px;
      flex: 0 0 100px;
  max-width: 100px;
}

}

@media screen and (min-width:1000px) {
.channelLinks {
  text-align: center;
  margin: -20px auto 0;
}
.channelLinks .channelLinksInner {
  -ms-flex-pack: center;
      justify-content: center;
}
.channelLinks h3 {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.channelLinks ul {
  overflow-x: hidden;
}
.channelLinks li:last-child {
  padding-right: 5px;
}
.channelLinks:after {
  background-image: none;
}
.channelLinks.hasImages h3 {
  padding: 0 25px;
  width: auto;
}
.channelLinks.hasImages li {
  width: calc(25% - 15px);
}
.channelLinks.hasImages li a .linkTitle {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.125rem;
}
.recipeDetail .channelLinks {
  padding-top: 10px;
  margin-bottom: 0;
}
/* async:start */
.socialLinks .channelLinks h3 {
  margin: 18px 0;
}
.socialLinks .channelLinks li {
  padding: 0 15px;
}
.socialLinks .channelLinks li a {
  padding: 5px;
}
.socialLinks .channelLinks li a:before {
  font-size: 2rem;
  line-height: 50px;
}
/* async:end */

}

@media screen and (min-width:1000px) {
.pageContainer > .channelLinks {
  margin-top: -30px;
  margin-left: -100%;
  margin-right: -100%;
}
.pageContainer > .channelLinks .channelLinksInner {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
/* async:start */
.socialLinks .channelLinks li a {
  transition: all 200ms ease;
}
.socialLinks .channelLinks li a:hover,
.socialLinks .channelLinks li a:focus {
  -webkit-transform: scale(1.075);
          transform: scale(1.075);
}
.socialLinks .channelLinks li a:before {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 2.5rem;
  line-height: 2.5rem;
}
/* async:end */

}

.ratingAndReviewCounts .ratingCountContainer .aggregateCount:before,
.ratingAndReviewCounts .questionCountContainer .aggregateCount:before {
  display: inline-block;
  color: #002e6d;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.25rem;
  line-height: 20px;
  vertical-align: middle;
}
.ratingAndReviewCounts .ratingCountContainer a,
.ratingAndReviewCounts .questionCountContainer a {
  color: #002e6d;
  text-align: center;
}
.ratingAndReviewCounts .ratingCountContainer a:hover,
.ratingAndReviewCounts .questionCountContainer a:hover {
  color: #002e6d;
  text-decoration: none;
}
.ratingAndReviewCounts .ratingCountContainer span,
.ratingAndReviewCounts .questionCountContainer span {
  display: inline-block;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  line-height: 20px;
}

.ratingAndReviewCounts .ratingCountContainer a .aggregateCount,
.ratingAndReviewCounts .questionCountContainer a .aggregateCount {
  font-weight: 700;
  color: #002e6d;
}

.featuredContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.featuredContent.includeMargins {
  margin: 15px auto;
}
.featuredContent.imageTop {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
}
.featuredContent .textContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding: 24px;
  font-style: normal;
}
.featuredContent .textContainer .headerText h2 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 12px;
  text-align: left;
}
.featuredContent .textContainer .headerText h2 a {
  display: inline-block;
}
.featuredContent .textContainer .headerText h2 a {
  color: #3273f8;
  text-decoration: underline;
}
.featuredContent .textContainer .headerText h2 a:hover,
.featuredContent .textContainer .headerText h2 a:focus {
  text-decoration: none;
}
.featuredContent .textContainer p {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin-bottom: 12px;
}
.featuredContent .textContainer .appliedTextColorLinks a {
  color: inherit;
}
.featuredContent .textContainer .linkButton a {
  display: inline-block;
  color: #002e6d;
  background-color: #fff;
  border: 2px solid;
  font-weight: 600;
  line-height: 19px;
}
.featuredContent .imageContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.featuredContent .imageContainer img {
  max-width: 100%;
  height: auto;
  display: block;
}

.featuredContent .textContainer .linkButton.invertButtonStyle a {
  background-color: white;
  color: #002e6d;
}
.featuredContent .textContainer .linkButton a {
  color: white;
  background-color: #002e6d;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
}
.featuredContent.darkBlueTheme .textContainer {
  background-color: #002e6d;
}
.featuredContent.darkBlueTheme .textContainer .headerText h2 {
  color: white;
}
.featuredContent.darkBlueTheme .textContainer p {
  color: white;
}
.featuredContent.darkBlueTheme .textContainer p span {
  color: white;
}
.featuredContent.darkBlueTheme .textContainer p a,
.featuredContent.darkBlueTheme .textContainer p a:focus {
  color: white;
  outline-color: white;
}
.featuredContent.darkBlueTheme .textContainer .linkButton a {
  border: none;
  background-color: white;
  color: #002e6d;
}
.featuredContent.creamTheme .textContainer {
  background-color: #fff9e4;
}

@media screen and (min-width:767px) {
.featuredContent.includeMargins {
  margin: 30px auto;
}
.featuredContent .imageContainer picture {
  width: 100%;
}
.featuredContent .imageContainer picture img {
  width: 100%;
  object-fit: contain;
}

}

@media screen and (min-width:1000px) {
.featuredContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
}
.featuredContent.imageLeft {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}
.featuredContent.imageRight {
  -ms-flex-direction: unset;
      flex-direction: unset;
}
.featuredContent .textContainer {
  padding: 24px 80px;
  -ms-flex-pack: center;
      justify-content: center;
  width: 50%;
}
.featuredContent .textContainer .headerText h2 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.6875rem;
  line-height: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 12px;
}
.featuredContent .textContainer .headerText h2 a {
  display: inline-block;
}
.featuredContent .textContainer .headerText h2 a {
  color: #3273f8;
  text-decoration: underline;
}
.featuredContent .textContainer .headerText h2 a:hover,
.featuredContent .textContainer .headerText h2 a:focus {
  text-decoration: none;
}
.featuredContent .imageContainer {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
}

}

.cgcContentList {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  text-align: left;
}
.cgcContentList .contentListSort {
  display: inline-block;
  width: 49%;
  position: relative;
  vertical-align: bottom;
  margin: 20px 0;
}
.cgcContentList .contentListSort .contentListSortLabel {
  display: block;
  margin-bottom: 6px;
}
.cgcContentList .contentListSort .contentListDropdown > .contentListInnerButton {
  all: inherit;
  width: 100%;
}
.cgcContentList .contentListSort .contentListDropdownButton {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: normal;
  padding: 10px 6px;
  border: solid 1px #d5cece;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  background-color: #fbfbfb;
}
.cgcContentList .contentListSort .contentListDropdownButton:hover {
  cursor: pointer;
}
.cgcContentList .contentListSort .contentListDropdownButton:after {
  font-family: 'Icon Fonts';
  content: "\e91e";
  font-weight: bold;
  font-size: 14px;
  border-left: 1px dotted;
  border-right: none;
  padding: 1px 1px 0 7px;
  width: 14px;
  height: 15px;
}
.cgcContentList .contentListSort .contentListDropdownContainer {
  display: none;
  list-style-type: none;
  margin: -1px 0 0;
  padding: 0;
  z-index: 3;
  background-color: #eee;
  border-top: 0;
  width: 100%;
  border: 1px solid #d5cece;
  box-sizing: border-box;
}
.cgcContentList .contentListSort .contentListDropdownContainer .contentListDropdownItem {
  all: inherit;
  cursor: pointer;
  padding: 10px 6px;
  width: calc(100% - 12px);
  border-bottom: solid 1px #d5cece;
}
.cgcContentList .contentListSort .contentListDropdownContainer .contentListDropdownItem:hover,
.cgcContentList .contentListSort .contentListDropdownContainer .contentListDropdownItem:focus {
  background-color: #f6f6f6;
}
.cgcContentList .contentListSort.expanded .contentListDropdownButton:after,
.cgcContentList .contentListSort .expanded .contentListDropdownButton:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  border-left: none;
  border-right: 1px dotted;
  padding: 0 7px 1px 1px;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer {
  display: block;
  position: absolute;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer li.selected,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer li.selected {
  font-weight: bold;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer li .contentListDropdownItem,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer li .contentListDropdownItem {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
}
.cgcContentList .cgcSkeleton {
  position: relative;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcParent {
  padding-bottom: 10px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcChild {
  width: 50%;
  float: left;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcContentText,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  width: 100px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcContentText {
  margin: 10px 0px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcDate,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating,
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  height: 15px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcUserScreenName {
  width: 200px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcRating {
  float: left;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcContentText {
  width: 100%;
  height: 60px;
}
.cgcContentList .cgcSkeleton .cgcContentDetail .cgcMostHelpful {
  float: right;
}
.cgcContentList .showMoreButtonContainer {
  text-align: center;
}
.cgcContentList .cgcRatingsOnlyMessage,
.cgcContentList .showMoreButtonContainer,
.cgcContentList .cgcLoading {
  margin-top: 20px;
}

@media screen and (min-width:767px) {
.cgcRedesignContainer .contentListContainer {
  max-width: 1200px;
  margin: 0 auto;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection {
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 32px;
  gap: 32px;
  margin-bottom: 32px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 0 240px;
      flex: 1 0 240px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .iconAiSummary {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 4rem;
  line-height: 4rem;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryDivider {
  width: 1px;
  height: unset;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  margin: 0px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryBodyText {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.375rem;
  -ms-flex-line-pack: center;
      align-content: center;
}
.cgcRedesignContainer .contentListContainer .contentListHeader {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListCount {
  margin-bottom: 0;
}
.cgcRedesignContainer .contentListContainer .emptyListContainer {
  margin-top: 32px;
  padding: 32px;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer {
  margin-top: 32px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewTitle {
  width: 75%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewRating {
  width: 15%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentText {
  height: 40px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentCardBodyFields .contentUserInfo {
  width: 25%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentActions {
  width: 20%;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentFeedback {
  width: 20%;
}

}

.cgcRedesignContainer .contentListContainer {
  padding: 32px 20px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  background-color: #fff9e4;
  padding: 24px;
  margin-bottom: 24px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .iconAiSummary {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 3rem;
  line-height: 3rem;
  color: #3273f8;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader {
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: #3273f8;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  margin-bottom: 8px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a {
  display: inline-block;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a {
  color: #3273f8;
  text-decoration: underline;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a:hover,
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummaryHeader a:focus {
  text-decoration: none;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .reviewSummaryDisplay .aiSummarySubheading {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.6875rem;
  line-height: 1.125rem;
  margin-bottom: 0px;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryDivider {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background: #333;
  border-style: none;
}
.cgcRedesignContainer .contentListContainer .reviewSummarySection .aiSummaryBodyText {
  color: #333;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin-bottom: 0px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListCount {
  display: none;
  margin-bottom: 16px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  gap: 16px;
  min-height: 45px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortLabel {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
  color: white;
  font-weight: 600;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown {
  position: relative;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown > .sortDropdownInnerButton {
  all: inherit;
  min-width: 200px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown > .sortDropdownInnerButton:focus {
  outline: 3px dotted #002e6d;
  outline-offset: 3px;
  border-radius: 3px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownButtonText {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  gap: 8px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 10px 12px;
  border: 1px solid #888;
  border-radius: 6px;
  background-color: white;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownButtonText:hover {
  cursor: pointer;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownButtonText:after {
  font-family: 'Icon Fonts';
  content: "\e91e";
  font-size: 12px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer {
  display: none;
  list-style-type: none;
  border: 1px solid #888;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  width: calc(100% - 2px);
  margin-top: 8px;
  z-index: 2;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer .sortDropdownListItem {
  all: inherit;
  cursor: pointer;
  padding: 8px 16px;
  width: calc(100% - 32px);
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1.5rem;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer .sortDropdownListItem:hover,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdownListContainer .sortDropdownListItem:focus {
  background-color: #f5f5f6;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort.expanded .sortDropdownButtonText:after,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .expanded .sortDropdownButtonText:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort.expanded .sortDropdownListContainer,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .expanded .sortDropdownListContainer {
  display: block;
  position: absolute;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort.expanded .sortDropdownListContainer li.selected,
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .expanded .sortDropdownListContainer li.selected {
  font-weight: bold;
}
.cgcRedesignContainer .contentListContainer .emptyListContainer {
  margin-top: 24px;
  padding: 24px;
  background-color: white;
  text-align: center;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer {
  margin-top: 24px;
  text-align: center;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .skeletonPulse {
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewTitle {
  width: 100%;
  height: 26px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardReviewHeader .reviewRating {
  width: 30%;
  height: 20px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentText {
  width: 100%;
  height: 80px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardBody .contentCardBodyFields .contentUserInfo {
  width: 75%;
  height: 22px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentActions {
  width: 100%;
  height: 40px;
}
.cgcRedesignContainer .contentListContainer .contentList.skeletonLoaders .contentCardContainer .contentCardContent .contentCardMainContainer .contentCardFooter .contentFeedback {
  width: 50%;
  height: 18px;
}

.skeletonContainer .headerSkeleton {
  width: 300px;
  height: 32px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
  margin-bottom: 8px;
}
.skeletonContainer .descriptionSkeleton {
  width: 75%;
  min-width: 300px;
  height: 24px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
  margin-bottom: 10px;
}
.skeletonContainer .contentItemsWrapper {
  display: -ms-flexbox;
  display: flex;
}
.skeletonContainer .contentItemsWrapper .contentItem {
  margin: 12px;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemImage {
  width: auto;
  aspect-ratio: 1.77777778;
  margin-bottom: 16px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemInfo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin-bottom: 24px;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemInfo .itemTitle {
  width: 75%;
  height: 22px;
  margin-bottom: 8px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer .contentItemsWrapper .contentItem .itemInfo .ratingsAndReviewCount {
  width: 120px;
  height: 16px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer .contentItemsWrapper .contentItem .favoriteButton {
  width: 60px;
  height: 18px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.skeletonContainer.carouselList {
  padding: 24px 20px 14px;
}
.skeletonContainer.categoryTile {
  padding: 32px 16px;
}
.skeletonContainer.categoryTile .contentItemsWrapper {
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.skeletonContainer.categoryTile .contentItemsWrapper .contentItem .itemImage {
  aspect-ratio: 1;
  border-radius: 50%;
}
.skeletonContainer.categoryTile .contentItemsWrapper .contentItem .itemInfo {
  -ms-flex-align: center;
      -ms-grid-row-align: center;
      align-items: center;
}

.cgcRedesignContainer .contentListContainer .reviewSummarySection {
  border-radius: 24px;
}
.cgcRedesignContainer .contentListContainer .contentListHeader .contentListSort .sortDropdown > .sortDropdownInnerButton:focus {
  outline: 3px dotted white;
  outline-offset: 3px;
  border-radius: 3px;
}
.cgcRedesignContainer .contentListContainer .emptyListContainer {
  border-radius: 16px;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  color: #002e6d;
  background: #fff;
  border: solid 2px #002e6d;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton:hover {
  text-decoration: none;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton a {
  color: #fff;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton:focus {
  border-radius: 100px;
}
.cgcRedesignContainer .contentListContainer .showMoreButtonContainer .showMoreButton:focus {
  border-radius: 100px;
}

@media screen and (min-width:767px) {
.cgcContentList .contentListSort {
  width: 30%;
}
.cgcContentList .contentListSort .contentListDropdownButton {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  padding: 10px;
}
.cgcContentList .contentListSort .contentListDropdownButton:after {
  padding-left: 10px;
  padding-right: 0;
  font-size: 18px;
  width: 18px;
  height: 19px;
  font-weight: bold;
}
.cgcContentList .contentListSort.expanded .contentListDropdownButton:after,
.cgcContentList .contentListSort .expanded .contentListDropdownButton:after {
  padding-right: 10px;
  padding-left: 0;
}
.cgcContentList .contentListSort.expanded .contentListDropdownContainer li .contentListDropdownItem,
.cgcContentList .contentListSort .expanded .contentListDropdownContainer li .contentListDropdownItem {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 10px;
  width: calc(100% - 20px);
}

}

@media screen and (min-width:767px) {
.skeletonContainer.carouselList {
  padding: 40px 20px 30px;
}

}

@media screen and (min-width:1000px) {
.skeletonContainer {
  max-width: 1200px;
  margin: 0 auto;
}

}

.contentListContainer .carouselList {
  padding: 24px 0 14px;
}
.contentListContainer .carouselList .headingContainer {
  margin-bottom: 10px;
  padding: 0 20px;
}
.contentListContainer .carouselList .headingContainer h2 {
  margin-bottom: 8px;
  text-align: inherit;
}
.contentListContainer .carouselList .headingContainer .descriptionContainer {
  margin-bottom: 0;
}
.contentListContainer .carouselList .carouselContainer {
  padding-left: 20px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item {
  max-width: 250px;
  margin: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink {
  display: block;
  margin-bottom: 24px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage {
  position: relative;
  width: auto;
  margin-bottom: 16px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage img {
  width: 100%;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage .itemTag {
  position: absolute;
  top: 10px;
  left: 10px;
  max-width: calc(100% - 40px);
  padding: 5px 10px;
  background-color: white;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: bold;
  color: #3273f8;
  border-radius: 100px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemInfo .itemTitle {
  color: #002e6d;
  font-weight: bold;
  margin-bottom: 8px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink:hover {
  text-decoration: none;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink:hover .itemInfo .itemTitle {
  text-decoration: underline;
}

.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemImage .itemTag {
  background-color: #fff9e4;
  color: #002e6d;
}
.contentListContainer .carouselList.creamTheme {
  background-color: #fff9e4;
}
.contentListContainer .carouselList.darkBlueTheme {
  background-color: #002e6d;
}
.contentListContainer .carouselList.darkBlueTheme .headingContainer {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .headingContainer h2 {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .headingContainer .descriptionContainer a {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .navigationButton {
  border-color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .navigationButton:after {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .navigationButton:focus {
  outline-color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .itemsWrapper .itemsInner .item .itemLink {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .itemsWrapper .itemsInner .item .itemLink .itemInfo .itemTitle {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .carouselContainer .itemsWrapper .itemsInner .item .itemLink:focus {
  outline-color: white;
}

@media screen and (min-width:767px) {
.contentListContainer .carouselList {
  padding: 40px 20px 30px;
}
.contentListContainer .carouselList .headingContainer {
  padding: 0;
}
.contentListContainer .carouselList .carouselContainer {
  padding: 0;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(33.3% - 13px);
  max-width: 285px;
}

}

@media screen and (min-width:1000px) {
.contentListContainer .carouselList .headingContainer {
  max-width: 1200px;
  margin: 0 auto 10px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper {
  max-width: 1200px;
}
.contentListContainer .carouselList .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(25% - 15px);
}
.contentListContainer .carouselList.inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.contentListContainer .carouselList.inline .headingContainer {
  margin: 0 20px 0 44px;
  width: 25%;
  max-width: 236px;
}
.contentListContainer .carouselList.inline .carouselContainer {
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  max-width: 988px;
  width: 75%;
}
.contentListContainer .carouselList.inline .carouselContainer .itemsWrapper {
  max-width: 900px;
}
.contentListContainer .carouselList.inline .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(33.3% - 15px);
  max-width: 285px;
}

}

.carouselContainer .navigationButton {
  display: none;
  -ms-flex-item-align: center;
      align-self: center;
  background-color: transparent;
  border-radius: 100%;
  padding: 5px;
  border: 2px solid #002e6d;
}
.carouselContainer .navigationButton:after {
  display: block;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-family: 'Icon Fonts';
  content: "\e92a";
  color: #002e6d;
}
.carouselContainer .navigationButton.hidden {
  border-color: transparent !important;
}
.carouselContainer .navigationButton.hidden:after {
  visibility: hidden;
}
.carouselContainer .navigationButton.previousButton {
  margin-right: 10px;
}
.carouselContainer .navigationButton.previousButton:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.carouselContainer .navigationButton.nextButton {
  margin-left: 10px;
}
.carouselContainer .itemsWrapper .itemsInner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.carouselContainer .itemsWrapper .itemsInner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.carouselContainer .itemsWrapper .itemsInner .item {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
}

.carouselContainer .navigationButton {
  padding: 7px;
}
.carouselContainer .navigationButton:after {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1rem;
  content: "\e91e";
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.carouselContainer .navigationButton.previousButton:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media screen and (min-width:767px) {
.carouselContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
}
.carouselContainer .navigationButton {
  display: initial;
}
.carouselContainer .navigationButton.previousButton {
  -ms-flex-order: 1;
      order: 1;
}
.carouselContainer .navigationButton.nextButton {
  -ms-flex-order: 3;
      order: 3;
}
.carouselContainer .itemsWrapper {
  overflow-x: hidden;
  -ms-flex-order: 2;
      order: 2;
}

}

@media screen and (min-width:1000px) {
.carouselContainer .itemsWrapper {
  max-width: 1200px;
}

}

.contentListContainer .favoriteButton.buttonAsLink {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: normal;
  color: #333;
}
.contentListContainer .favoriteButton.buttonAsLink:before {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #888;
  margin-right: 8px;
}
.contentListContainer .favoriteButton.buttonAsLink.iconHeartFilled:before {
  color: #b4353d;
}
.contentListContainer .favoriteButton.buttonAsLink:hover:enabled {
  text-decoration: none;
}

.contentListContainer .favoriteButton.buttonAsLink {
  color: #002e6d;
  font-weight: 700;
}
.contentListContainer .favoriteButton.buttonAsLink:before {
  font-weight: normal;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink:before {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink.iconHeartFilled:before {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .favoriteButton.buttonAsLink:focus {
  outline-color: white;
}

.contentListContainer .ratingsAndReviewCount .stars:before {
  color: #002e6d;
}
.contentListContainer .ratingsAndReviewCount .ratingCount {
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  vertical-align: text-top;
}

.contentListContainer .ratingsAndReviewCount .ratingCount {
  color: #002e6d;
  vertical-align: unset;
}
.contentListContainer .carouselList.darkBlueTheme .ratingsAndReviewCount .stars:before,
.contentListContainer .carouselList.darkBlueTheme .ratingsAndReviewCount .stars:after {
  color: white;
}
.contentListContainer .carouselList.darkBlueTheme .ratingsAndReviewCount .ratingCount {
  color: white;
}

.contentListContainer .categoryTile {
  padding: 32px 16px;
}
.contentListContainer .categoryTile .headingContainer {
  margin-bottom: 12px;
}
.contentListContainer .categoryTile .headingContainer h2 {
  margin-bottom: 8px;
  text-align: inherit;
}
.contentListContainer .categoryTile .headingContainer .descriptionContainer {
  margin-bottom: 0;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem {
  margin: 12px;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink {
  display: block;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemImage {
  width: 100%;
  border-radius: 50%;
  margin-bottom: 8px;
  aspect-ratio: 1;
  object-fit: cover;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemTitle {
  text-align: center;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: bold;
}
.contentListContainer .categoryTile.carousel {
  padding-right: 0;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  -ms-flex-pack: unset;
      justify-content: unset;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper .contentItem {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
}

.contentListContainer .categoryTile.creamTheme {
  background-color: #fff9e4;
}
.contentListContainer .categoryTile.darkBlueTheme {
  background-color: #002e6d;
}
.contentListContainer .categoryTile.darkBlueTheme .headingContainer {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .headingContainer h2 {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .headingContainer .descriptionContainer a {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .tileContainer .contentItemsWrapper .contentItem .itemLink {
  color: white;
}
.contentListContainer .categoryTile.darkBlueTheme .tileContainer .contentItemsWrapper .contentItem .itemLink:focus {
  outline-color: white;
}

@media screen and (min-width:767px) {
.contentListContainer .categoryTile .headingContainer {
  max-width: 1200px;
  margin: 0 auto 12px;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemImage {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink .itemImage:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.contentListContainer .categoryTile .tileContainer .contentItemsWrapper .contentItem .itemLink:focus .itemImage {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.contentListContainer .categoryTile.carousel {
  padding-right: 16px;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  overflow-x: unset;
}
.contentListContainer .categoryTile.carousel .tileContainer .contentItemsWrapper .contentItem {
  -ms-flex: unset;
      flex: unset;
}

}

.verticalVideoList {
  margin: 20px 0;
}
.verticalVideoList .contentContainer {
  min-height: 600px;
}
.verticalVideoList .contentContainer .headingContainer {
  padding: 20px;
  text-align: center;
  display: table-cell;
}
.verticalVideoList .contentContainer .headingContainer img.listBadge,
.verticalVideoList .contentContainer .headingContainer h2.listHeading,
.verticalVideoList .contentContainer .headingContainer p.listDescription {
  margin-bottom: 12px;
}
.verticalVideoList .contentContainer .headingContainer img.listBadge {
  max-width: 140px;
}
.verticalVideoList .contentContainer .headingContainer h2 {
  text-align: inherit;
}
.verticalVideoList .contentContainer .carouselContainer {
  padding: 0 0 10px 20px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item {
  max-width: 250px;
  width: 250px;
  margin: 10px;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer {
  width: 100%;
  margin-bottom: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer {
  -ms-flex-order: 3;
      order: 3;
  margin: 0 16px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoTitle {
  margin-bottom: 10px;
  font-weight: 600;
  overflow-wrap: break-word;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoDescription {
  margin-bottom: 10px;
  color: #333;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer {
  position: relative;
  -ms-flex-order: 1;
      order: 1;
  margin-bottom: 16px;
  width: 100%;
  aspect-ratio: 0.5625;
  background-color: #ddd;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoThumbnail {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton {
  color: #fff;
  border-radius: 100%;
  border: 3px solid #FFF;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 50%;
  padding: 15px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  line-height: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  transition-duration: 0.2s;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton:hover {
  border-color: #002e6d;
  background-color: #002e6d;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton:before {
  font-family: 'Icon Fonts';
  content: "\e941";
  font-size: 1.3rem;
  -webkit-text-stroke: 1.5px;
}
@media (min-width: 767px) {
  .verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton:before {
    font-size: 1.8rem;
  }
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton.instagram {
  padding: 0;
  width: 54px;
  height: 54px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton.instagram:hover,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .videoPlayButton.instagram:focus {
  text-decoration: none;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer {
  position: absolute;
  top: 0;
  width: 100%;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 0.5625;
  margin-bottom: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.videoPlayer > * {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage {
  background-color: rgba(0, 0, 0, 0.5);
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer {
  position: absolute;
  top: 0;
  height: calc(100% - 80px);
  margin: 20px;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  background-color: white;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 {
  color: #3273f8;
  font-family: 'Poppins', sans-serif;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1.375rem;
  line-height: 1.625rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 900;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a {
  display: inline-block;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a {
  color: #3273f8;
  text-decoration: underline;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a:hover,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer .embeddedContentContainer .embeddedContent.privacyMessage .privacyMessageContainer h3 a:focus {
  text-decoration: none;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks {
  -ms-flex-order: 2;
      order: 2;
  margin: 0 16px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink {
  display: inline-block;
  text-decoration: underline;
  font-weight: 500;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
  text-wrap: balance;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink:hover,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink:hover {
  text-decoration: none;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink:after,
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink:after {
  display: inline-block;
  margin-left: 5px;
  font-family: 'Icon Fonts';
  content: "\e92a";
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.5625rem;
  line-height: 0.5625rem;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.audioDescriptionLink {
  margin-bottom: 10px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer .accessibilityLinks a.textTranscriptLink {
  margin-bottom: 20px;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer a.ctaLink {
  -ms-flex-order: 4;
      order: 4;
  margin: 0 16px;
  font-weight: 600;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer.noTextContent {
  margin-bottom: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item .itemContainer.noTextContent .videoPlayerContainer {
  margin-bottom: 0;
}
.verticalVideoList .contentContainer .carouselContainer.singleVideo {
  padding: 0 20px 10px;
}
.verticalVideoList .contentContainer .carouselContainer.singleVideo .itemsWrapper .itemsInner {
  -ms-flex-pack: center;
      justify-content: center;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-scroll-snap-type: x mandatory;
      -ms-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item {
  scroll-snap-align: start;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  width: 250px;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoTitle {
  width: 50%;
  height: 20px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item .itemContainer .videoHeadingContainer .videoDescription {
  width: 100%;
  height: 40px;
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner .item .itemContainer .videoPlayerContainer {
  -webkit-animation: graypulse 2s infinite;
          animation: graypulse 2s infinite;
}
.pageHome .verticalVideoList {
  margin: 0;
}

.verticalVideoList .carouselContainer .itemsWrapper .itemsInner .item .itemContainer a.ctaLink {
  text-decoration: underline;
}
.verticalVideoList .carouselContainer .itemsWrapper .itemsInner .item .itemContainer a.ctaLink:hover {
  text-decoration: none;
}
.verticalVideoList.creamTheme {
  background-color: #fff9e4;
}
.verticalVideoList.blueTheme {
  background-color: #002e6d;
}
.verticalVideoList.blueTheme .headingContainer h2.listHeading,
.verticalVideoList.blueTheme .headingContainer p.listDescription {
  color: white;
}
.verticalVideoList.blueTheme .headingContainer h2.listHeading a,
.verticalVideoList.blueTheme .headingContainer p.listDescription a {
  color: white;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink {
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 25px;
  color: #333;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.9375rem;
  line-height: 1.25rem;
  background: #002e6d;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  vertical-align: middle;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  color: #002e6d;
  background: #fff;
  border: solid 2px #002e6d;
  border-radius: 100px;
  text-transform: uppercase;
  padding: 10px 20px;
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: 900;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink:hover {
  text-decoration: none;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink a {
  color: #fff;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink:focus {
  border-radius: 100px;
}
.verticalVideoList.blueTheme .headingContainer a.listCtaLink:focus {
  border-radius: 100px;
}
.verticalVideoList.blueTheme .carouselContainer .navigationButton {
  border-color: white;
}
.verticalVideoList.blueTheme .carouselContainer .navigationButton:after {
  color: white;
}
.verticalVideoList.blueTheme .carouselContainer .navigationButton:focus {
  outline-color: white;
}

@media screen and (min-width:767px) {
.verticalVideoList .contentContainer .carouselContainer {
  padding: 0 20px;
}

}

@media screen and (min-width:1000px) {
.verticalVideoList .contentContainer {
  margin: 0 auto;
  padding: 40px;
  max-width: 1200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  min-height: 500px;
}
.verticalVideoList .contentContainer .headingContainer {
  -ms-flex: 1 0 auto;
      flex: 1 0 auto;
  text-align: left;
  max-width: calc(50% - 80px);
}
.verticalVideoList .contentContainer .carouselContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  min-width: 600px;
  padding: 0;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper {
  width: 100%;
}
.verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner .item {
  width: calc(33.3% - 17px);
}
.verticalVideoList .contentContainer .carouselContainer.singleVideo {
  padding: 0;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper {
  overflow-x: hidden;
}
.verticalVideoList .contentContainer .carouselContainer.skeletonLoader .itemsWrapper .itemsInner {
  margin: 0 44px;
}
.pageArticle .verticalVideoList .contentContainer,
.pageCollection .verticalVideoList .contentContainer {
  display: block;
}
.pageArticle .verticalVideoList .contentContainer .headingContainer,
.pageCollection .verticalVideoList .contentContainer .headingContainer {
  text-align: center;
}
.pageArticle .verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner,
.pageCollection .verticalVideoList .contentContainer .carouselContainer .itemsWrapper .itemsInner {
  -ms-flex-pack: center;
      justify-content: center;
}

}


/*# sourceMappingURL=global.583ca017525d414b24be1a8a75791b2d.css.map*/