.carouselContainer .navigationButton {
  padding: 7px;
}
.carouselContainer .navigationButton:after {
  /* Calculation here normalizes font-size values based on an assumed base font size of 16px */
  font-size: 1rem;
  line-height: 1rem;
  content: "\e91e";
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.carouselContainer .navigationButton.previousButton:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
